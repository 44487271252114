import React from 'react';
import lunr from 'lunr';
import { generateSearchIndex } from './generate-search-index';
import { LunrQuery, LunrQueryOpts } from './lunr-query';

export interface SearchResult {
  id: string;
  'Organization Name': string;
  Description: string;
  [key: string]: string;
}

export interface SearchPayload {
  query: string;
  index: lunr.Index;
  store: Record<string, any>;
  jobNumber: number;
  filters?: string[];
}

export interface SearchResponse {
  results: SearchResult[];
  jobNumber: number;
}

let searchRequest: Promise<{
  index: lunr.Index;
  store: Record<string, any>;
}> | null = null;

let searchWorker: Worker | null;
let jobNumber = 0; // Track job number so we know what is coming in and out of the worker

function getSearchWorker(): Worker {
  if (!searchWorker) {
    searchWorker = new Worker(new URL('./search.worker.tsx', import.meta.url));
  }

  return searchWorker;
}

async function getSearchData(): Promise<{
  index: lunr.Index;
  store: Record<string, any>;
}> {
  // TODO: WIP not great that we are fetching the data here
  const searchIndexLocal = localStorage.getItem('searchIndex');
  const indxStorage: Promise<{
    index: lunr.Index;
    store: Record<string, any>;
  }> | null = searchIndexLocal ? Promise.resolve(JSON.parse(searchIndexLocal)) : null;
  searchRequest = indxStorage || generateSearchIndex();
  const res = await searchRequest;

  searchRequest = null;

  return res;
}

export default async function search(
  query: LunrQueryOpts[],
  filters?: string[]
): Promise<SearchResult[]> {
  const currentJobNumber = jobNumber; // track current job number

  jobNumber++; // increment job number for next time

  const { index, store } = await getSearchData();

  return new Promise((resolve) => {
    function cb({ data }: MessageEvent<SearchResponse>) {
      if (data.jobNumber) {
        getSearchWorker().removeEventListener('message', cb);

        resolve(data.results);
      }
    }

    getSearchWorker().onmessage = cb;

    const payload: SearchPayload = {
      query: new LunrQuery(query).toString(),
      index,
      store,
      jobNumber,
      filters,
    };

    getSearchWorker().postMessage(payload);
  });
}
