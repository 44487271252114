export interface LunrQueryOpts {
  query: string;
  fuzzy?: number;
}

/**
 * Builds a Lunr compatible query string
 */
export class LunrQuery {
  private cache?: string;

  // eslint-disable-next-line no-useless-constructor
  constructor(private queryParts: LunrQueryOpts[] = []) {}

  toString(): string {
    if (this.cache) {
      return this.cache;
    }

    const query: string[] = this.queryParts.map((opts) => {
      let parsed = opts.query.trim().split(' ');

      const { fuzzy } = opts;

      if (fuzzy) {
        // individual tokens must be at least twice as long as the fuzzy option
        parsed = parsed.map((q) => (q.length > fuzzy * 2 ? `${q}~${opts.fuzzy}` : q));
      }

      return parsed.join(' ');
    });

    return query.join(' ');
  }
}
