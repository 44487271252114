import React from 'react';

export default function ExternalLinkIcon() {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none">
      <path
        d="M16 16.5H2V2.5H9V0.5H2C0.89 0.5 0 1.4 0 2.5V16.5C0 17.6 0.89 18.5 2 18.5H16C17.1 18.5 18 17.6 18 16.5V9.5H16V16.5ZM11 0.5V2.5H14.59L4.76 12.33L6.17 13.74L16 3.91V7.5H18V0.5H11Z"
        fill="#2469DF"
      />
    </svg>
  );
}
