import React, { useEffect, useState } from 'react';
import Select, { MenuProps, OptionProps, ActionMeta } from 'react-select';

// assests
import FilterIcon from './../../assets/images/icons/filter-icon';

import './SearchFilter.scss';

interface SearchFilterProps {
  options: SelectOption[];
  filterLabel: string;
  onApply: (
    values: readonly SelectOption[] | null,
    previousValues: readonly SelectOption[] | null
  ) => void;
  filterValues?: readonly SelectOption[];
}

export interface SelectOption {
  value: string;
  label: string;
}

const CustomOption = ({ innerRef, innerProps, isSelected, label }: OptionProps<any>) => {
  return (
    <div className="search-filter__option" ref={innerRef} {...innerProps}>
      <input type="checkbox" checked={isSelected} id={label} name={label} readOnly />
      <label className="p" htmlFor={label}>
        {label}
      </label>
    </div>
  );
};

const CustomMenu = ({
  children,
  innerProps,
  filterLabel,
  handleApply,
  clearValue,
}: React.PropsWithChildren<MenuProps<any>> & { filterLabel: string; handleApply: () => void }) => (
  <div className="search-filter__menu" {...innerProps}>
    <p className="search-filter__menu-title">Filter by {filterLabel}</p>
    <div className="search-filter__options-list-wrapper">{children}</div>
    <div className="search-filter__button-wrapper">
      <button className="btn-clear" onClick={clearValue}>
        CLEAR
      </button>
      <button className="btn-primary" onClick={handleApply}>
        APPLY
      </button>
    </div>
  </div>
);

const CustomIndicatorsContainer = () => (
  <div className="search-filter_indicator-container">
    <FilterIcon />
  </div>
);

export default function SearchFilter({
  options,
  filterLabel,
  onApply,
  filterValues,
}: SearchFilterProps) {
  const [previousSelectedOptions, setPreviousSelectedOptions] = useState<
    readonly SelectOption[] | null
  >([]);
  const [selectedOptions, setSelectedOptions] = useState<readonly SelectOption[] | null>([]);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleApply = (options?: SelectOption[]) => {
    const applyOptions = options && options.length >= 0 ? options : selectedOptions;

    onApply(applyOptions, previousSelectedOptions);
    setPreviousSelectedOptions(applyOptions);
  };

  const handleChange = (
    selectedItems: readonly SelectOption[],
    actionType?: ActionMeta<SelectOption>
  ) => {
    setSelectedOptions(selectedItems);

    if (actionType?.action === 'clear') {
      handleApply([]);
    }
  };

  const closeMenu = () => {
    // timeout needed to avoid closing menu before applying filters on mobiles
    setTimeout(() => setMenuIsOpen(false), 100);
  };

  useEffect(() => {
    if (filterValues) {
      handleChange(filterValues);
      setPreviousSelectedOptions(filterValues);
    }
  }, [JSON.stringify(filterValues)]);

  return (
    <div className="search-filter">
      <Select
        components={{
          Option: CustomOption,
          Menu: (props) => (
            <CustomMenu {...props} filterLabel={filterLabel} handleApply={handleApply} />
          ),
          IndicatorsContainer: CustomIndicatorsContainer,
        }}
        onChange={handleChange}
        options={options}
        isMulti={true}
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={closeMenu}
        closeMenuOnSelect={false}
        closeMenuOnScroll={false}
        hideSelectedOptions={false}
        isClearable={false}
        placeholder={filterLabel}
        controlShouldRenderValue={false}
        value={selectedOptions}
        blurInputOnSelect={false}
        classNamePrefix="usaid-search"
      />
    </div>
  );
}
