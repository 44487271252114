import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './ServiceCard.scss';

// assets
import arrowIcon from '../../assets/images/icons/arrow-right.svg';

interface ServiceCardProps {
  title: string;
  image: {
    src: any;
    alt: string;
  };
}

export default function ServiceCard({
  title,
  children,
  image,
}: React.PropsWithChildren<ServiceCardProps>) {
  const [active, setActive] = useState(false);

  return (
    <div className="service-card">
      <div className="service-card__container">
        <h6>{title}</h6>
        {!active ? (
          <div className="service-card_inner">
            <img className="service-card__image" src={image.src} alt={image.alt}></img>
            <div className="service-card__learn-more">
              <button
                onClick={() => setActive(true)}
                className="button-link service-card__learn-more-button"
              >
                LEARN MORE
                <img className="service-card__icon" src={arrowIcon} alt={'arrow-icon'} />
              </button>
            </div>
          </div>
        ) : (
          <>
            <div>{children}</div>
            <div className="service-card__see-less">
              <button onClick={() => setActive(false)} className="button-link">
                See less
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
