// COMPONENTS
import LoginForm from '../components/Login/LoginForm';
import photoLogin02 from '../assets/images/photo-login-02.png';

export default function Login() {
  return (
    <section className="login-screen">
      <img src={photoLogin02} className="login-image" alt="Woman smiling down at cellphone" />
      <div className="container login-wrapper">
        <LoginForm />
      </div>
    </section>
  );
}
