import React, { useState } from 'react';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Navigation, Pagination, Scrollbar, A11y, Controller } from 'swiper';
import { useNavigate } from 'react-router-dom';

// Direct React component imports
import { Swiper, SwiperSlide } from 'swiper/react';

// Styles must use direct files imports
import 'swiper/scss'; // core Swiper
import 'swiper/scss/navigation'; // Navigation module
// import 'swiper/modules/pagination/pagination.scss'; // Pagination module
import globalNetwork from '../../assets/images/global-network.png';
import lmicFirms from '../../assets/images/lmic-firms.png';
import smallFirms from '../../assets/images/small-firms.png';
import usaidFirms from '../../assets/images/usaid-firms.png';
import thematicTechnicalAreas from '../../assets/images/thematic-technical-areas.png';

import './Slider.scss';

interface SliderContent {
  id: number;
  title: string;
  description: string;
  link: string;
  image: string;
}

const data: SliderContent[] = [
  {
    id: 1,
    title: '55+',
    description: 'Firms within our Global Network',
    link: 'Link',
    image: globalNetwork,
  },
  {
    id: 2,
    title: '26',
    description: 'Firms operating in LMICs',
    link: 'Link',
    image: lmicFirms,
  },
  {
    id: 3,
    title: '22',
    description: 'Firms with USAID experience',
    link: 'Link',
    image: smallFirms,
  },
  {
    id: 4,
    title: '29',
    description: 'Firms identify as Small or Disadvantaged',
    link: 'Link',
    image: usaidFirms,
  },
  {
    id: 5,
    title: '18',
    description: 'Thematic or Technical areas with Network Specialists',
    link: 'Link',
    image: thematicTechnicalAreas,
  },
];

export default function Slider() {
  // store controlled swiper instance
  const [imageSwiper, setImageSwiper] = useState(null);
  const [navSwiper, setNavSwiper] = useState(null);
  const [thumbSwiper, setThumbSwiper] = useState(null);
  const navigate = useNavigate();

  return (
    <>
      <Swiper
        controller={{ control: navSwiper }}
        modules={[A11y, Controller]}
        onSwiper={setImageSwiper}
        spaceBetween={0}
      >
        {data.map((card, index) => {
          return (
            <div key={'card.id' + index}>
              <SwiperSlide key={index + 'card-id'}>
                <div
                  className="slide"
                  style={{
                    backgroundImage: 'url(' + card.image + ')',
                  }}
                ></div>
              </SwiperSlide>
            </div>
          );
        })}
      </Swiper>
      <div className="container slider-navigation-container">
        <div className="slider-details">
          <div className="slider-navigation-wrapper">
            <Swiper
              controller={{ control: [imageSwiper, thumbSwiper] }}
              initialSlide={0}
              modules={[Navigation, A11y, Controller]}
              navigation
              onSwiper={setNavSwiper}
              rewind
              slidesPerView={1}
              spaceBetween={0}
            >
              {data.map((card, index) => {
                return (
                  <div key={'card.id' + index}>
                    <SwiperSlide key={index + 'card-id'}>
                      <div className="nav-slide">
                        <h3 className="h0">{card.title}</h3>
                        <h5>{card.description}</h5>
                      </div>
                    </SwiperSlide>
                  </div>
                );
              })}
            </Swiper>
            <div className="slider-navigation-button-wrapper">
              <button className="slider-navigation-button" onClick={() => navigate('/partners')}>
                View Our Partners
              </button>
            </div>
          </div>
          <div className="slider-thumb-wrapper"></div>
        </div>
      </div>
    </>
  );
}
