import React, { useEffect, useRef, useState } from 'react';

import SearchInput from './SearchInput';
import SearchFilter, { SelectOption } from './SearchFilter';
import SearchResultCard from './SearchResultCard';

// assets
import './SearchContainer.scss';
import search, { SearchResult } from '../../search/search';
import CloseIcon from '../../assets/images/icons/close-icon';
import {
  Countries,
  Regions,
  Sectors,
  SmallBusiness,
  TechnicalAreas,
  ThematicAreas,
  USBased,
} from '../../utils/filter-lists';
import ToggleSwitch from './ToggleSwitch';

interface filterValues {
  regions: SelectOption[];
  countries: SelectOption[];
  sectors: SelectOption[];
  techAreas: SelectOption[];
  themeAreas: SelectOption[];
  usBased: boolean;
  smallBusiness: boolean;
}

/**
 * Updates filterValues for all dropdowns and tags.
 *
 * @param {SelectOption[]} added Array of new filters added.
 * @param {SelectOption[]} removed Array of filters removed.
 * @param {filterValues} filterValues Current values of filters.
 * @return {filterValues}
 */
export const updateFilters = (
  added: readonly SelectOption[],
  removed: readonly SelectOption[],
  filterValues: filterValues
): filterValues => {
  const tmpOptions = Object.assign({}, filterValues);

  const addOption = (optArr: SelectOption[], option: SelectOption) => optArr.push(option);
  const removeOption = (optArr: SelectOption[], option: SelectOption) => {
    const idx = optArr.indexOf(option);
    if (idx > -1) {
      optArr.splice(idx, 1);
    }
  };

  const callbackFn = (operation: (optArr: SelectOption[], option: SelectOption) => void) => {
    return (option: SelectOption) => {
      if (ThematicAreas.includes(option)) {
        operation(tmpOptions.themeAreas, option);
      } else if (TechnicalAreas.includes(option)) {
        operation(tmpOptions.techAreas, option);
      } else if (Sectors.includes(option)) {
        operation(tmpOptions.sectors, option);
      } else if (Regions.includes(option)) {
        operation(tmpOptions.regions, option);
      } else if (Countries.includes(option)) {
        operation(tmpOptions.countries, option);
      } else if (USBased.value === option.value) {
        tmpOptions.usBased = !tmpOptions.usBased;
      } else if (SmallBusiness.value === option.value) {
        tmpOptions.smallBusiness = !tmpOptions.smallBusiness;
      } else {
        console.log('no matches: ', option.label);
      }
    };
  };

  added.forEach(callbackFn(addOption));
  removed.forEach(callbackFn(removeOption));

  return tmpOptions;
};

export default function SearchContainer() {
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const [queryValue, setQueryValue] = useState('');
  const [filterList, setFilterList] = useState<SelectOption[]>([]);
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [filterValues, setFilterValues] = useState<filterValues>({
    regions: [],
    countries: [],
    sectors: [],
    techAreas: [],
    themeAreas: [],
    usBased: false,
    smallBusiness: false,
  });

  /**
   * Applies updates to filter selection for single tag or dropdown filter. Function will add or remove values by comparing the two arrays. If only one array is passed to function, will only perform addition of filters.
   *
   * @param {SelectOption[]} values Updated array of selected filters from filter dropdown or clickable tag.
   * @param {SelectOption[]} previousValues Optional - array of previously selected filters from same dropdown for comparison. Function will only handle removal of options if previousValues is not undefined.
   * @return {void}
   */
  const handleApply = (
    values: readonly SelectOption[] | null,
    previousValues?: readonly SelectOption[] | null
  ) => {
    if (!values) {
      return;
    }

    let tmpFilterList = [...filterList];

    let removed: SelectOption[] = [];
    if (values && previousValues) {
      // check if values removed from list
      removed = previousValues.filter((x) => !values.includes(x));
      tmpFilterList = tmpFilterList.filter((x) => !removed.includes(x));
    }
    // only add values if not already in list
    const added = values.filter((x) => !tmpFilterList.includes(x));

    tmpFilterList.push(...added);
    setFilterList(tmpFilterList);

    setFilterValues(updateFilters(added, removed, filterValues));
  };

  // perform search with filter and query values
  function onSearch() {
    setQueryValue(queryValue);

    const filterListStrings: string[] = filterList.map((x) => x.value);

    search([{ query: queryValue, fuzzy: 2 }], filterListStrings).then((res) => {
      setSearchResults(res);
    });
  }

  const removeQueryValue = () => {
    setQueryValue('');
  };

  const removeAllFilters = () => {
    handleApply([], [...filterList]);
    removeQueryValue();
  };

  // determine class for filter tags based on category
  const tagClass = (option: SelectOption): string => {
    if (ThematicAreas.includes(option)) {
      return 'thematic';
    } else if (TechnicalAreas.includes(option)) {
      return 'technical';
    } else if (Sectors.includes(option)) {
      return 'sector';
    } else if (Regions.includes(option)) {
      return 'region';
    } else if (Countries.includes(option)) {
      return 'country';
    }
    return '';
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>, cb: () => void) => {
    if (e.key === 'Enter') {
      cb();
    }
  };

  useEffect(() => {
    onSearch();
  }, [queryValue, JSON.stringify(filterList)]);

  const FilterOptions = () => (
    <>
      <SearchFilter
        options={Regions}
        filterLabel="Region"
        onApply={handleApply}
        filterValues={filterValues.regions}
      />
      <SearchFilter
        options={Countries}
        filterLabel="Country"
        onApply={handleApply}
        filterValues={filterValues.countries}
      />
      <SearchFilter
        options={Sectors}
        filterLabel="Sector"
        onApply={handleApply}
        filterValues={filterValues.sectors}
      />
      <SearchFilter
        options={TechnicalAreas}
        filterLabel="Technical Areas"
        onApply={handleApply}
        filterValues={filterValues.techAreas}
      />
      <SearchFilter
        options={ThematicAreas}
        filterLabel="Thematic Areas"
        onApply={handleApply}
        filterValues={filterValues.themeAreas}
      />
      <SearchInput onSearch={setQueryValue} initialValue={queryValue} />
    </>
  );

  const ToggleOptions = () => (
    <>
      <ToggleSwitch option={USBased} value={filterValues.usBased} filterCb={handleApply} />
      <ToggleSwitch
        option={SmallBusiness}
        value={filterValues.smallBusiness}
        filterCb={handleApply}
      />
    </>
  );

  return (
    <>
      <div className="search-container">
        <div className="search-filter-container">
          <div className="flex-row flex-col-gap-20">
            <FilterOptions />
          </div>
          <div className="flex-row flex__justify-start flex-col-gap-24 padding-top-40">
            <ToggleOptions />
          </div>
        </div>
        <div className="search-filter-container-mobile">
          {!showMobileFilters ? (
            <button
              className="btn-primary"
              aria-label="show filtering options"
              onClick={() => setShowMobileFilters(true)}
            >
              SHOW FILTERS
            </button>
          ) : (
            <button
              className="btn-secondary"
              aria-label="hide filtering options"
              onClick={() => setShowMobileFilters(false)}
            >
              HIDE FILTERS
            </button>
          )}
          {showMobileFilters && (
            <div className="flex-column flex-row-gap-20 padding-top-20">
              <FilterOptions />
              <ToggleOptions />
            </div>
          )}
        </div>
      </div>
      {(queryValue !== '' || filterList.length > 0) && (
        <div>
          {searchResults.length > 0 ? (
            <h4>Showing results for:</h4>
          ) : (
            <h4>Sorry, no results for:</h4>
          )}
          <div className="search-tags-container">
            {queryValue !== '' && (
              <div
                className={`search-filter-tag results-tag`}
                onClick={removeQueryValue}
                onKeyDown={(e) => handleKeyPress(e, removeQueryValue)}
                role="button"
                tabIndex={0}
                aria-label={`Remove "${queryValue}" query from filtering`}
              >
                &quot;{queryValue}&quot;
                <CloseIcon className="search-filter-tag--close-icon" />
              </div>
            )}

            {filterList.map((filter, i) => (
              <div
                onClick={() => handleApply([], [filter])}
                onKeyDown={(e) => handleKeyPress(e, () => handleApply([], [filter]))}
                key={i}
                className={`search-filter-tag results-tag search-filter-tag--${tagClass(filter)}`}
                role="button"
                tabIndex={0}
                aria-label={`Remove "${filter.label}" from filtering`}
              >
                {filter.label}
                <CloseIcon className="search-filter-tag--close-icon" />
              </div>
            ))}

            {filterList.length > 1 && (
              <button className="search-filters-remove" onClick={removeAllFilters}>
                Remove filters
              </button>
            )}
          </div>
        </div>
      )}
      <div className="search-result-cards-container">
        {searchResults.length > 0 &&
          searchResults.map((res, i) => (
            <SearchResultCard filterCb={handleApply} result={res} key={i} />
          ))}
      </div>
    </>
  );
}
