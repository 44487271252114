import lunr from 'lunr';

// TODO: type this better

async function getData() {
  const data = await fetch(`data.json`).then((res) => res.json());
  return data;
}

export function createIndex(this: any, data: any[]) {
  const store: Record<string, any> = {};
  const index = lunr(function () {
    this.ref('id');
    this.field('Organization Name');
    this.field('Description');

    data.forEach(function (this: any, node: Record<string, string>) {
      this.add(node);
      store[node.id] = node;
    }, this);
  });

  return { index, store };
}

export async function generateSearchIndex(): Promise<{
  index: lunr.Index;
  store: Record<string, any>;
}> {
  const data = await getData();
  const indexedData = createIndex(data.data);
  const serializedIndex = JSON.stringify(indexedData, null);

  localStorage.setItem('searchIndex', serializedIndex);
  return JSON.parse(serializedIndex);
}
