import footerIcon from '../../assets/images/icons/footer-icon-01.svg';

import './Footer.scss';

export default function Footer() {
  return (
    <footer className="footer" role="contentinfo">
      <div className="container footer-wrapper">
        <img src={footerIcon} alt="Deloitte logo" className="footer-logo" />
        <p className="label footer-disclaimer">
          Deloitte refers, individually or jointly, to Deloitte Touche Tohmatsu Limited (“DTTL”) (
          private company limited by guarantee , under UK law), and its network of member firms and
          their associated entities. DTTL and each of its member firms are entities with their own
          independent legal personality. DTTL (also called &quot;Deloitte Global&quot;) does not
          provide services to clients. Please see{' '}
          <a href="http://www.deloitte.com/about">http://www.deloitte.com/about</a> for a detailed
          description of DTTL and its member firms.
        </p>
      </div>
    </footer>
  );
}
