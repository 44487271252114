import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserAuth } from '../../UserAuthContext';

import './LoginForm.scss';

export default function LoginForm() {
  const navigate = useNavigate();

  const email = 'test@test.com';
  const [password, setPassword] = useState('');
  const { user, logIn, errorMsg } = useUserAuth();

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await logIn(email, password);
    } catch (error) {
      console.log('the message:' + errorMsg);
    }
  };

  useEffect(() => {
    const x = query.get('code');
    if (user) {
      navigate('/home');
    } else if (x) {
      setPassword(x);
      handleSubmit();
    } else {
      navigate('/');
    }
  }, [user, navigate, query]);

  return (
    <form className="login-form__container" onSubmit={handleSubmit}>
      <h1 className="login-form__title">
        Enhancing open, inclusive, and secure digital ecosystems around the world.
      </h1>
      <p className="login-form__description">
        We can help you solve complex development problems with innovative methodologies,
        technologies, and accelerators.
      </p>
      <div className="login-form__field-container">
        <input
          aria-labelledby="password-field"
          id="password-field"
          className="login-form__field"
          type="password"
          placeholder="Enter password to learn more"
          label="Enter password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        {errorMsg ? (
          <span className="login-form__field--error-text">
            Password is incorrect. Please try again.
          </span>
        ) : (
          ''
        )}
      </div>
      <button className="login-form__submit" type="submit">
        Let’s go
      </button>
    </form>
  );
}
