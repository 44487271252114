import React, { PropsWithChildren } from 'react';
import ExternalLinkIcon from '../../assets/images/icons/external-link-icon';

import './ExternalLink.scss';

interface ExternalLinkProps {
  href: string;
  className?: string;
}

export default function ExternalLink({
  href,
  className,
  children,
}: PropsWithChildren<ExternalLinkProps>) {
  return (
    <a className={`external-link ${className}`} href={href} target="_blank" rel="noreferrer">
      {children}
      <ExternalLinkIcon />
    </a>
  );
}
