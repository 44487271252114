import React from 'react';
import ExternalLink from '../ExternalLink/ExternalLink';
import './PhotoCard.scss';

export interface PhotoCardProps {
  title: string;
  description: string;
  photo: {
    src: any;
    alt: string;
  };
  link: {
    title: string;
    href: string;
  };
}

export default function PhotoCard({ title, description, photo, link }: PhotoCardProps) {
  return (
    <div className="photocard__container">
      <h3 className="photocard__title">{title}</h3>
      <img src={photo.src} alt={photo.alt} />
      <p className="photocard__description">{description}</p>
      <ExternalLink href={link.href}>{link.title}</ExternalLink>
    </div>
  );
}
