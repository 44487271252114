import React, { useEffect, useState } from 'react';
import { SelectOption } from './SearchFilter';

import './ToggleSwitch.scss';

interface ToggleSwitchProps {
  option: SelectOption;
  value: boolean;
  filterCb: (values: SelectOption[], previousValues?: SelectOption[]) => void;
}

export default function ToggleSwitch({ option, value, filterCb }: ToggleSwitchProps) {
  const [toggled, setToggled] = useState(value);

  const handleChange = () => {
    setToggled(!toggled);
    if (!toggled) {
      filterCb([option], []);
    } else {
      filterCb([], [option]);
    }
  };

  useEffect(() => {
    setToggled(value);
  }, [value]);

  return (
    <div className={`toggle-switch ${toggled ? 'toggled' : ''}`}>
      <button
        className="toggle"
        aria-label={`Show only results with "${option.label}"`}
        onClick={handleChange}
      >
        <span className="toggle_slider" hidden />
      </button>
      <div aria-hidden>{option.label}</div>
    </div>
  );
}
