import React from 'react';

interface IconProps {
  className?: string;
}

export default function CloseIcon(props: IconProps) {
  return (
    <svg viewBox="0 0 14 14" fill="none" className={props.className}>
      <title id="search-icon">Close Icon</title>
      <path
        d="M1 1L13 13M1 13L13 1L1 13Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
