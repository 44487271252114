import React from 'react';
import { ConsultantCardProps } from '../../components/ConsultantCard/ConsultantCard';

// Generic Placeholder Image
const consultantImage = require('../images/generic-grey.png');

// Assets
const anneb = require('../images/anneb.jpeg');
const andrewc = require('../images/andrewc.jpeg');
const oonie = require('../images/oonie.jpeg');
const cheld = require('../images/cheld.jpeg');
const katieg = require('../images/katieg.jpeg');
const kevink = require('../images/kevink.jpeg');
const heewon = require('../images/heewon.png');
const kendra = require('../images/kendra.jpeg');
const agapiti = require('../images/agapiti.jpeg');
const megm = require('../images/megm.jpeg');
const timm = require('../images/timm.jpeg');
const evam = require('../images/evam.jpeg');
const kelsy = require('../images/kelsy.jpeg');
const timn = require('../images/timn.jpeg');
const anap = require('../images/anap.jpeg');
const lisas = require('../images/lisas.jpeg');
const mahadia = require('../images/mahadia.jpeg');
const scotty = require('../images/scotty.jpeg');
const ronda = require('../images/ronda.jpeg');
const saml = require('../images/saml.jpeg');

const exCards: ConsultantCardProps[] = [
  {
    name: 'Susan Abbott',
    focus: [
      'Cybersecurity',
      'Collaborative Learning & Adaptation',
      'Countering Mis/Dis/Mal Information',
      'Monitoring Evaluation & Learning',
    ],
    locations: [
      'Bangladesh',
      'Bosnia and Herzegovina',
      'Ecuador',
      'Djibouti',
      'Georgia',
      'Hungary',
      'Jordan',
      'Kenya',
      'Moldova',
      'Mozambique',
      'North Macedonia',
      'the Philippines',
      'Serbia',
      'Sierra Leone',
      'Somalia',
      'Tanzania',
      'Ukraine',
      'Zambia',
      'Zimbabwe',
    ],
    yearsExperience: '23',
    bio: 'Senior Civil Society and Media Development Technical Expert at Counterpart International. Provided consulting services in business development and MERL, a lot of media experience.',
    linkedIn: 'https://www.linkedin.com/in/susan-a-1184931/',
    image: consultantImage,
  },
  {
    name: 'Anne Bitga',
    focus: [
      'Blockchain & Crypto',
      'Collaborative Learning & Adaptation',
      'Digital Financial Services',
      'Digital Identity',
      'Inclusive Development',
      'Monitoring Evaluation & Learning',
    ],
    locations: [
      'Burkina Faso, Kenya, South Africa, Egypt, Morocco, Nigeria, Ghana, Jordan, Liberia, Guyana, Lebanon, Uganda, Tanzania, Mozambique, Tajikistan, Ethiopia, Iraq',
    ],
    yearsExperience: '10+',
    bio: "Director of Project Delivery at Making Cents International. Making Cents' Technical Lead on USAID ACAT since 2018 focusing on Gender and Youth Strategy",
    linkedIn: 'https://www.linkedin.com/in/anne-bitga-greteman/',
    image: anneb,
  },
  {
    name: 'Andrew Carmona',
    focus: [
      'Collaborative Learning & Adaptation',
      'Digital Skills and Literacy',
      'Geographical Information Systems',
      'Inclusive Development',
      'Monitoring Evaluation & Learning',
    ],
    locations: [
      'Benin, Burundi, Dominican Republic, El Salvador, Ethiopia, Ghana, Guatemala, Haiti, Honduras, India, Kenya, Liberia, Malawi, Mexico, Nicaragua, Niger, Senegal, Somalia, Tanzania, TimorLeste',
    ],
    yearsExperience: '14',
    bio: 'Senior QA and Technical Manager at QED, Global health, WASH, agriculture, education, youth leadership, and post-disaster projects',
    linkedIn: 'https://www.linkedin.com/in/ajcarmona/',
    image: andrewc,
  },
  {
    name: 'Oonie Chase',
    focus: [
      'Digital Financial Services',
      'Social Media',
      'Human/User Centered Design',
      'Systems Thinking',
    ],
    locations: ['U.S. Hong Kong, Australia'],
    yearsExperience: '25',
    bio: 'Partner at IDEO. Designer creating meaningful, simple, complex parts, builds teams across the streams of design and storytelling',
    linkedIn: 'https://www.linkedin.com/in/ooniechase/',
    image: oonie,
  },
  {
    name: 'Chelsea Dascher',
    focus: [
      'Blockchain & Crypto',
      'Digital Financial Services',
      'Digital Identity',
      'Digital Ecosystem Analysis',
      'Gender Digital Divide',
      'ICT Workforce Analysis',
    ],
    locations: [
      'Nepal, Kenya, Jordan, India, Brazil, Worked on projects in: Palestine/Gaza, Jordan, Iraq, Egypt, Afghanistan, India, Brazil, Argentina, Uzbekistan, Tajikistan ,Kyrgyzstan, Pakistan, Indonesia, Timor Leste, Myanmar, Cambodia, Laos, Uganda, Ghana, Lebanon',
    ],
    yearsExperience: '8+',
    bio: 'Manager, Online Communities and Knowledge Manager, USAID, at Making Cents International. MEL professional, Youth, Gender, Social Impact',
    linkedIn: 'https://www.linkedin.com/in/chelseadascher/',
    image: cheld,
  },
  {
    name: 'Samantha Levine Finley',
    focus: [
      'Collaborative Learning & Adaptation',
      'Human/User-Centered Design',
      'Monitoring Evaluation & Learning',
      'Social Media',
    ],
    locations: ['Kenya, Honduras, Jamaica, Tanzania'],
    yearsExperience: '15',
    bio: "Senior Learning Advisor at QED. QED's lead on the USAID KDLT project",
    linkedIn: 'https://www.linkedin.com/in/samantha-levine-finley/',
    image: saml,
  },
  {
    name: 'Katrin Gorham',
    focus: [
      'Countering Mis/Dis/Mal information',
      'Data Ecosystems',
      'Digital Skills and Literacy',
      'Graphic Design/Multimedia',
      'Human/User-Centered Design',
      'Social Media',
    ],
    locations: [
      'Nepal, Mongolia, Kenya, ad hoc experience in various other countries in Asia/Africa.',
    ],
    yearsExperience: '13',
    bio: 'Marketing & Comms Specialist at QED',
    linkedIn: 'https://www.linkedin.com/in/katiegorham/',
    image: katieg,
  },
  {
    name: 'Kevin Koy',
    focus: [
      'Data Ecosystems',
      'Geographical Information Systems',
      'Human/User-Centered Design',
      'Remote Sensing',
      'Responsible Data',
      'Systems Thinking',
    ],
    locations: [
      'Brazil, China, Costa Rica, Ethiopia, Laos, Myanmar, South Africa, Sweden, Turkey, United States, Vietnam',
    ],
    yearsExperience: '23',
    bio: 'Senior Director Data x Design at IDEO. Data Science and AI Programs. Executive Director at UC Berkely Geospatial Innovation Facility',
    linkedIn: 'https://www.linkedin.com/in/kevin-koy/',
    image: kevink,
  },
  {
    name: 'Heewon Lee',
    focus: [
      'Digital Skills and Literacy',
      'Internet of Things (IoT)',
      'Social Media',
      'Graphic Design/ Multimedia',
      'Human/User Centered Design',
      'Inclusive Development',
    ],
    locations: [
      'Canada, Greece, Guatemala, Japan, Kenya, Malaysia, Mongolia, Nepal, South Korea, Sudan, Sweden, Tanzania, Uganda, Zambia, USA',
    ],
    yearsExperience: '9',
    bio: 'Industrial designer and educator at MIT D-Lab. His work focuses on creating and facilitating design educational programs in the humanitarian and development contexts.',
    linkedIn: '',
    image: heewon,
  },
  {
    name: 'Kendra Leith',
    focus: [
      'AI',
      'Digital Financial Services',
      'Internet of Things (IoT)',
      'Collaborative Learning & Adaptation',
      'Human/User Centered Design',
      'Inclusive Development',
    ],
    locations: ['Ghana, Guatemala, India, Uganda, Tanzania, Kenya'],
    yearsExperience: '13',
    bio: 'Associate Director for Research at MIT Dlab. She oversees and coordinates the D-Lab research team and implements research on participatory design and conducts user and market research studies.',
    linkedIn: 'https://www.linkedin.com/in/kendra-leith-75308b39/',
    image: kendra,
  },
  {
    name: 'Agapiti Manday',
    focus: [
      'Data Ecosystem',
      'Digital Financial Services',
      'Digital Skills and Literacy',
      'Country Assessments',
      'Human/User Centered Design',
      'Inclusive Development',
    ],
    locations: [
      'Tanzania, Uganda, Ghana, Burundi, Rwanda, Finland, Sweden, United Kingdom, Kosovo',
    ],
    yearsExperience: '14+',
    bio: 'Co-Founder and Executive Director at Tanzania Data Lab. Focuses on structuring, implementing and strategic management of successful ventures including community initiatives. Higher up Leader/Manager.',
    linkedIn: 'https://www.linkedin.com/in/agapiti-manday-78776812/?originalSubdomain=tz',
    image: agapiti,
  },
  {
    name: 'Aleksandra Markovich',
    focus: [
      'Collaborative Learning & Adaptation',
      'Inclusive Development',
      'Monitoring Evaluation & Learning',
      'Social Media',
    ],
    locations: [
      'Armenia, Serbia, USA, Kenya, Afghanistan, South Sudan, Colombia, Bosnia and Herzegovina',
    ],
    yearsExperience: '14',
    bio: "Organizational development, strategic planning, change management, MEL, instructional design. Led change management efforts for USAID's CPS and managed the Conflict Technical Capacity Building and Training TO with USAID's CVP.",
    linkedIn: 'https://www.linkedin.com/in/aleksandra-m-b7414b222/',
    image: consultantImage,
  },
  {
    name: 'Megan McDermott',
    focus: [
      'Collaborative Learning & Adaptation',
      'Human/User-Centered Design',
      'Systems Thinking',
    ],
    locations: ['Nicaragua, Philippines, Ethiopia, Rwanda'],
    yearsExperience: '7',
    bio: '-Associate Director of the Innovation & Practice at Pulte Institute for Global Development',
    linkedIn: 'https://www.linkedin.com/in/mcdermottmegan',
    image: megm,
  },
  {
    name: 'Tim McQuillin',
    focus: ['Digital Economy', 'AI Solutions', 'DECA'],
    locations: ['US, Ukraine, + 16 countries'],
    yearsExperience: '25+',
    bio: 'Digital Development Consultant. Strategy, advisory, digital transformation, research, assessments, use case development, journey mapping, ICT4D, ethical AI, responsible data, client success, business development, product marketing, brand positioning, technology, data analysis, open innovation, impact measurement, public-private partnership, social entrepreneurship, social enterprise, emerging and frontier markets.',
    linkedIn: 'https://www.linkedin.com/in/timmcquillin',
    image: timm,
  },
  {
    name: 'Eva Mejia',
    focus: [
      'Collaborative Learning & Adaptation',
      'Human/User-Centered Design',
      'Systems Thinking',
    ],
    locations: ['Mexico, Chile, Guatemala, Peru, United States'],
    yearsExperience: '18',
    bio: 'Executive Director of Learning at IDEO.',
    linkedIn: 'https://www.linkedin.com/in/eva-mejia-ed-l-d-9a523b77/',
    image: evam,
  },
  {
    name: 'Kelsy Nelson',
    focus: [
      'Monitoring, Evaluation, Research, and Learning',
      'Implementing Digital Social Impact Programmes',
    ],
    locations: [
      'Africa: Burkina Faso, Ethiopia, Ghana, Kenya, Morocco, Nigeria, South Africa, Uganda',
      'Americas: Argentina, Bolivia, Canada, Mexico, Peru, United States of America',
      'Asia: Pakistan',
      'Europe: Belgium, United Kingdom',
    ],
    yearsExperience: '15+',
    bio: 'Monitoring, Evaluation, Research and Learning (MERL) Consultant',
    linkedIn: 'https://www.linkedin.com/in/kelsynelson?trk=people-guest_people_search-card',
    image: kelsy,
  },
  {
    name: 'Timothy Nourse',
    focus: [
      'Blockchain & Crypto',
      'Collaborative Learning & Adaptation',
      'Digital Financial Services',
      'Digital Identity',
      'Gender Digital Divide',
      'Inclusive Development',
    ],
    locations: [
      'Bulgaria, Croatia, Egypt, Haiti, Jordan, Morocco, Philippines, Senegal, Tajikistan, Uganda, Tanzania, Turkey, Afghanistan, Cambodia, Guinea, Iraq, Lebanon, Liberia, Sierra Leone, Sri Lanka, Sudan, West Bank/Gaza, Yemen',
    ],
    yearsExperience: '25',
    bio: 'President of Making Cents International. Provide the overall vision, technical direction, leadership, and management of the company and to oversee its Technical Services, Operations, Finance, and Business Development divisions',
    linkedIn: 'https://www.linkedin.com/in/timothy-nourse-59868b3/',
    image: timn,
  },
  {
    name: 'Ana Pantelic',
    focus: [
      'Digital Financial Services',
      'Digital Skills and Literacy',
      'Last Mile Connectivity',
      'Gender Digital Divide',
      'Inclusive Development',
      'Systems Thinking',
    ],
    locations: [
      'Uganda, Tanzania, South Africa, Colombia, Brazil, Paraguay, Peru, Dominican Republic, Honduras, Guatemala, El Salvador, Mexico, India, Serbia, Spain, United States',
    ],
    yearsExperience: '14',
    bio: 'Executive Director at MIT D-Lab. Provide leadership and vision, build partnerships and collaborations, and strategically direct the development, implementation, and sustainability of D-Lab initiatives',
    linkedIn: 'https://www.linkedin.com/in/anapantelic/',
    image: anap,
  },
  {
    name: 'Lisa Schirch',
    focus: [
      'Digital Ecosystem Analysis',
      'Digital Skills and Literacy',
      'ICT Policy Development',
      'Online Safeguarding & Protection',
      'Social Media',
    ],
    locations: ['Afghanistan, Iraq, Fiji, Israel, Palestine, Over 20 countries'],
    yearsExperience: '30',
    bio: 'Sr Professor of the Practice of Peace Studies. Digital Peacebuilding and Peaceteach. Wrote a book on Social Media Impacts on Conflict and Democracy',
    linkedIn: 'https://www.linkedin.com/in/lisa-schirch-9492231/',
    image: lisas,
  },
  {
    name: 'Mahadia Tunga',
    focus: [
      'Data Science Curriculum',
      'Data Ecosystems',
      'Digital Government',
      'Digital Skills and Literacy',
      'Country Assessments',
      'Digital Ecosystem Analysis',
      'Gender Digital Divide',
    ],
    locations: ['Tanzania'],
    yearsExperience: '13',
    bio: 'Co-Founder and Director of Data Science, Research and Capacity Development of the Tanzania Data Lab (dLab). Develops online data science curriculum and trained over 3,000 people/orgs in at least 10 countries.',
    linkedIn: 'https://www.linkedin.com/in/mahadiatunga/',
    image: mahadia,
  },
  {
    name: 'Scott Yetter',
    focus: [
      'Collaborative Learning & Adaptation',
      'Human/User-Centered Design',
      'Monitoring Evaluation & Learning',
    ],
    locations: ['Egypt, Jordan, West Bank, Central Asia, Kenya, Ghana, Rwanda, Madagascar, Uganda'],
    yearsExperience: '20+',
    bio: 'Technical Director for Organizational Development at QED Group. Design, implementation and evaluation of programs involving the training and capacity building of local organizations, capturing and formalizing lessons learned, and transferring effective methodologies to practitioners.',
    linkedIn: 'https://www.linkedin.com/in/scott-yetter-736929139/',
    image: scotty,
  },
  {
    name: 'Ronda ZeleznyGreen',
    focus: [
      'Digital Skills and Literacy',
      'Social Media',
      'Telecommunications & 5G',
      'Collaborative Learning & Adaptation',
      'Gender Digital Divide',
      'ICT Policy Development',
    ],
    locations: [
      'Kenya, Malawi, Zimbabwe, Madagascar, Botswana, South Africa, Senegal, Nigeria, Tanzania, Rwanda, Ghana, Zambia, Uganda, Jordan, Lebanon, Philippines, Samoa, Bangladesh, South Korea, Cambodia, Laos, Mongolia, Bhutan, United States, United Kingdom, Spain',
    ],
    yearsExperience: '17',
    bio: 'Program Director of the Capacity Accelerator Network (CAN) at DataDotOrg. Digital changemaker creating social learning systems to empower Black people, women, people with disabilities, and other marginalized populations in the technology and education sectors. Serving as a mobile technologist, trainer, and researcher.',
    linkedIn: 'https://www.linkedin.com/in/rondazeleznygreen/',
    image: ronda,
  },
];

export default exCards;
