import React from 'react';
import './Banner.scss';

interface Props {
  title: string;
  description?: string;
  image: {
    src: any;
    alt: string;
  };
  type: 'home' | 'services' | 'partners' | 'our-people';
}

export default function Banner({ title, description, image, type }: Props) {
  return (
    <div className={`banner__container banner__${type}`}>
      <img src={image.src} className="banner-image" alt={image.alt} />
      <div className="banner-wrapper container">
        <div className="banner__text-container">
          <h1 className="h0">{title}</h1>
          {description && <p>{description}</p>}
        </div>
      </div>
    </div>
  );
}
