import React, { SyntheticEvent, useState } from 'react';

// assets
import searchIcon from '../../assets/images/icons/search-icon-01.svg';

import './SearchInput.scss';

interface SearchInputProps {
  initialValue: string;
  onSearch: (value: string) => void;
}

export default function SearchInput({ initialValue, onSearch }: SearchInputProps) {
  const [value, setValue] = useState(initialValue);
  const [focusState, applyFocusState] = useState(false);

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    setValue(value);
  }

  function submit(e: SyntheticEvent<any>) {
    e.preventDefault();
    onSearch(value);
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key.toUpperCase() === 'ENTER') {
      submit(e);
    }
  }

  return (
    <form
      data-testid="search-input-form"
      className={`search-input__form ${focusState ? 'input_focus' : ''}`}
    >
      <input
        type="text"
        data-testid="search-input-input"
        name="search"
        className="search-input__input"
        placeholder="Search"
        onChange={onInputChange}
        onKeyDown={onKeyDown}
        value={value}
        aria-label="Search"
        onFocus={() => applyFocusState(true)}
        onBlur={() => applyFocusState(false)}
      />

      <button onClick={submit} className="search-input__button" aria-label="Search">
        <img src={searchIcon} className="search-input__icon" alt={'search icon'} />
      </button>
    </form>
  );
}
