import React from 'react';

// COMPONENTS
import Banner from '../components/Banners/Banner';
import LinkCard, { LinkCardProps } from '../components/Cards/LinkCard';
import PhotoCard, { PhotoCardProps } from '../components/Cards/PhotoCard';
import Slider from '../components/Slider/Slider';

// PHOTO ASSETS
import photoHome01 from '../assets/images/photo-home-01.png';
import photoHome02 from '../assets/images/photo-home-02.png';
import photoHome03 from '../assets/images/photo-home-03.png';

// ICON ASSETS
import homeIcon01 from '../assets/images/icons/home-icon-01.svg';
import homeIcon02 from '../assets/images/icons/home-icon-02.svg';
import homeIcon03 from '../assets/images/icons/home-icon-03.svg';

// RESOURCES ASSETS
const pulsingHealthcare = require('../assets/resources/Pulsing the Healthcare Supply Chain.pdf');
const usingData = require('../assets/resources/Using Data to Improve Utility Operations_USAID DSIPA.pdf');

const linkCardData: LinkCardProps[] = [
  {
    title: 'Our Services',
    icon: homeIcon01,
    description:
      'We provide leading practices, practical applications, and advice to help your programs get the most impact from using the latest digital technologies such as Artificial Intelligence, Mobile Apps, Geospatial Analysis.',
    link: {
      title: 'Learn more about our services',
      href: '/services',
    },
  },
  {
    title: 'Our Partners',
    icon: homeIcon03,
    description:
      'We connect you to global and local organizations with digital expertise to help innovate, pilot, implement, and scale digital solutions within your programs.',
    link: {
      title: 'Learn more about our partners',
      href: '/partners',
    },
  },
  {
    title: 'Our Consultants',
    icon: homeIcon02,
    description:
      'We introduce you and your counterparts to experts at the cutting edge of emerging technology, cybersecurity, advanced analytics, and the intersection of digital with sectors and crosscutting areas such as gender equity and social inclusion.',
    link: {
      title: 'Learn more about our consultants',
      href: '/consultants',
    },
  },
];

const photoCardData: PhotoCardProps[] = [
  {
    title:
      'Using Data to Improve Utility Operations: How Digitization Can Benefit the Tunisian Electricity Sector',
    photo: {
      src: photoHome03,
      alt: 'Small group with hardhats looking at documents.',
    },

    description:
      ' In low‐ and middle‐income countries, typical healthcare supply chain challenges result from a high number of players and components involved. These challenges are amplified by obstacles such unreliable infrastructure, regulatory  hurdles, technical complexities like connectivity, and lack of developed third party logistics for cold chain facilities or transport services. Although  distribution practices aim to prevent commodity degradation, they are often unevenly applied due to a lack of  visibility across the supply chain. Use of IoMT in addition to these procedures provides enhanced insights for commodity monitoring, warehouse management, and commodity waste reduction.',
    link: {
      title: 'Read the full article',
      href: usingData,
    },
  },
  {
    title:
      'Pulsing the Healthcare Supply Chain: Deploying the Internet  of Medical Things for Low and Middle-Income Countries',
    photo: {
      src: photoHome02,
      alt: 'Woman administrating medical shot to smiling child.',
    },

    description:
      'Electric utilities are responsible for operating complex systems composed of thousands of generation assets, transmission and distribution infrastructure components, and end user connections. Maintaining a steady flow of electricity is vital to almost every productive industry and, when that flow is interrupted, the results can be devastating—even deadly. This is especially true in low‐ and middle‐income countries (LMICs) where utilities and regulators face the difficult challenge of managing the energy sector without access to the kind of timely and accurate information that enables data‐driven decisions to address challenges such as demand growth, aging infrastructure, and an increasing push toward renewable energy.',
    link: {
      title: 'Read the full article',
      href: pulsingHealthcare,
    },
  },
];

export default function Home() {
  return (
    <>
      <section className="section--blue">
        <Banner
          title="We believe that digital is an opportunity to do things better."
          image={{ src: photoHome01, alt: 'Man controlling a drone in a field' }}
          type="home"
        />
      </section>
      <section className="section">
        <div className="container">
          <div className="flex-row">
            <div className="home-page__text-description">
              <section className="home-page__intro">
                <p className="h5">
                  Welcome to the USAID DigiLEAP portal, a one-stop-shop for all things digital at
                  USAID, provided by USAID’s Digital &amp; Local Ecosystem Acceleration Program
                  (DigiLEAP).
                </p>
                <p>
                  DigiLEAP is a five-year, global buy-in mechanism for USAID Missions and other
                  Operating Units to use for digital upskilling, program cycle integration, and
                  technical assistance activities. DigiLEAP’s goal is to accelerate the
                  operationalization of USAID’S digital strategy.
                </p>
                <p>
                  <span className="semi-bold">USAID Staff</span> – Use this portal to explore
                  DigiLEAP&apos;s customizable service offerings, partners, and global consultants.
                  Contact the DigiLEAP team using the form below to learn more and discuss your
                  team&apos;s digital development support needs.
                </p>
              </section>
            </div>
          </div>
        </div>

        <section className="padding-bottom-90 padding-top-90 usaid-section__btn-row">
          <p className="h5">
            We will leverage USAID’s robust digital tools and frameworks to guide DigiLEAP programs
            to support USAID’s work toward a future where digital technology promotes inclusive
            growth, fosters resilient and democratic societies, and empowers all, including the most
            vulnerable.
          </p>
          <div className="btn-section">
            <a
              href={'https://www.usaid.gov/digital-development/usaid-digital-strategy'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn-secondary">USAID Digital Strategy</button>
            </a>
            <a
              href="https://www.usaid.gov/sites/default/files/2022-05/DAI-1146_DRLA_Fctsheet-final_8.24.21_Update.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn-secondary">USAID Agency Learning Agenda</button>
            </a>
            <a
              href="https://www.usaid.gov/digital-development/digital-ecosystem-framework#:~:text=Wednesday%2C%20August%2024%2C%202022%20USAID%E2%80%99s%20new%20Digital%20Ecosystem,the%20design%20of%20effective%2C%20sustainable%20projects%20and%20activities."
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn-secondary">USAID Digital Ecosystem Framework</button>
            </a>
            <a
              href="https://deem.digitaldevelopment.org/#/em_grid"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn-secondary">USAID Digital Ecosystem Evidence Map</button>
            </a>
            <a
              href="https://www.usaid.gov/digital-strategy/implementation-tracks/track1-adopt-ecosystem/digital-ecosystem-country-assessments"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn-secondary">USAID Digital Ecosystem Country Assessments</button>
            </a>
          </div>
        </section>
        {/* <hr className="banner-alternate__hr center"></hr> */}
        <div className="container padding-top-70 padding-bottom-70">
          <div className="flex-row padding-bottom-70">
            {linkCardData.map((card, index) => (
              <LinkCard key={index} {...card} />
            ))}
          </div>
          <div className="btn-row">
            <button className="btn-primary cta" role="link">
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSdd7QFptNAa_Fg9tciK78MgltbYu6zgChqjmtoOrHL3vmb-JQ/viewform?usp=sf_link">
                Interested in Engaging with Us?
                <p>Contact us to learn more or request staff and firms to support your mission.</p>
              </a>
            </button>
          </div>
        </div>
      </section>
      <section className="section--blue padding-top-50 slider-container">
        <div className="container container__standard">
          <hr className="hr home-page__hr home-page__hr--alt" />
          <h2>About Our Partners</h2>
        </div>
        <Slider />
      </section>
      <section className="section__standard">
        <div className="container container__standard">
          <div className="flex-column">
            <h2 className="h2--dark margin-bottom-30">Digital Development Case Studies</h2>
            <div className="flex-row padding-bottom-70">
              {photoCardData.map((card, index) => (
                <PhotoCard key={index} {...card} />
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
