import React from 'react';
import { Link } from 'react-router-dom';
import ExternalLink from '../ExternalLink/ExternalLink';
import './RoundedCard.scss';

const externalLinkIcon = require('../../assets/images/icons/partners-icon-01.svg');

export interface RoundedCardProps {
  alt: string;
  image: any;
  primary: boolean;
  tag: string;
  description: string;
  link: { href: string; title: string };
}

export default function RoundedCard({
  alt,
  image,
  primary,
  tag,
  description,
  link,
}: RoundedCardProps) {
  return (
    <div className={`rounded-card__container ${primary ? 'rounded-card__container--primary' : ''}`}>
      <div className="rounded-card__text-container">
        <div className="rounded-card__tag">{tag}</div>
        <img src={image} className="rounded-card__image" alt={alt}></img>
        <p className="rounded-card__description">{description}</p>
      </div>

      <ExternalLink href={link.href}>{link.title}</ExternalLink>
    </div>
  );
}
