import React from 'react';

// Components
import ServiceCard from '../components/Cards/ServiceCard';
import Banner from '../components/Banners/Banner';

// Assets
import heroImage from '../assets/images/services/heroimage-04.svg';
import policiesImage from '../assets/images/services/policies.svg';
import researchImage from '../assets/images/services/research.svg';
import digitalEconomyImage from '../assets/images/services/digital-economy.svg';

export default function DigitalServices() {
  return (
    <>
      <section className="section--light-blue">
        <Banner
          title="Digital Services"
          description="We will support USAID with digital services that weave inclusive development, local solutions, human-centered design, systems thinking, and collaborative learning and adaptive management throughout their design and implementation. Our services incorporate breakthrough innovations and services for emerging priorities to enable digital solutions that are secure, connected, interoperable, sustainable, and adaptable to changing needs over time."
          image={{ src: heroImage, alt: 'Man pointing at colorful chart' }}
          type={'services'}
        />
      </section>
      <section className="section__standard  margin-bottom-60">
        <div className="container container__narrow padding-top-70 padding-bottom-70">
          <div className="flex-row flex-wrap flex-row-gap-60-24">
            <ServiceCard
              title="Policies &amp; Frameworks and Research, Learning, &amp; Training"
              image={{ src: policiesImage, alt: 'Computer with colorful charts on screen' }}
            >
              <>
                <p>
                  We will provide robust, end-to-end services to produce extensive programmatic
                  resources. Our team is adept at generating data-driven strategy and policy
                  frameworks, drafting guidance on integrating existing operations with emerging
                  priorities and principles, and developing approaches for monitoring and evaluating
                  to measure effectiveness and impact of such activities and resources. To support
                  understanding and adoption of insights, we will provide services to equip staff
                  with the information, knowledge, and skillsets that they need in an easily
                  digestible and accessible manner by identifying learning needs, designing
                  curriculum, and facilitating trainings among other activities. Through such
                  services, our team has:
                </p>
                <p className="bold padding-top-20">Through such services, our team has:</p>
                <ul className="ul">
                  <li>Developed best practices for safeguarding privacy and protection of data</li>
                  <li>
                    Provided a roadmap on how to integrate digital principles into existing programs
                    and activities
                  </li>
                  <li>
                    Generate conceptual frameworks and an evidence base for measuring changes
                    attributable to learning
                  </li>
                </ul>
              </>
            </ServiceCard>
            <ServiceCard
              title="Capacity Building for Digital Economy, Digital Infrastructure, and Digital Society, Rights, &amp; Governance"
              image={{ src: researchImage, alt: 'Computer mouse connected to tablet' }}
            >
              <>
                <p>
                  We will strengthen the openness, inclusiveness, and security of country-level
                  digital ecosystems through our services. Our team has experience assessing the
                  digital ecosystems of countries and providing recommendations regarding risks and
                  opportunities on digital technology to support programming in addressing digital
                  inequalities.
                </p>
                <p className="bold padding-top-20">As part of these activities, we have:</p>
                <ul className="ul">
                  <li>
                    Designed digital and data governance to maximize the benefits and minimize the
                    risks of digital applications
                  </li>
                  <li>
                    Provided technical assistance to implement best practices for responsible usage
                    of technology
                  </li>
                  <li>
                    Assessed and provided roadmap for revision of practices to support mission
                    efforts to address digital inequalities
                  </li>
                </ul>
              </>
            </ServiceCard>
            <ServiceCard
              title="Strategic Engagement, Assessment, Development, Demonstration, and Pilots"
              image={{ src: digitalEconomyImage, alt: 'Image of fingerprint screening.' }}
            >
              <>
                <p>
                  We recognize the necessity of bringing the right partners to the table, and we are
                  dedicated conveners of advisors and stakeholders. By driving opportunities for
                  strategic engagement with external stakeholders, our team will equip USAID
                  stakeholders with the support to inform, develop, and implement sustainable
                  digital strategies and solutions in a manner which accelerates deployment, builds
                  capacity, and enables resilience. In addition to engaging strategically, our team
                  will conduct landscape assessments to determine existing capacity, develop use
                  cases and demonstrations in partnership with local communities, and pilot programs
                  to determine improvements and implementation next steps.
                </p>
                <p className="bold padding-top-20">Our team has:</p>
                <ul className="ul">
                  <li>
                    Identified and partnered with governments, civil societies, and private sector
                    stakeholders to develop digital policies
                  </li>
                  <li>
                    Engaged strategic partners to develop shared research and learning priorities
                  </li>
                  <li>
                    Stood up and supported channels of communication such as conferences and round
                    tables with key stakeholders
                  </li>
                </ul>
              </>
            </ServiceCard>
          </div>
        </div>
      </section>
    </>
  );
}
