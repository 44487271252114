import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics, logEvent } from "firebase/analytics";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCw9Y0-CrOd4fknLtbQqTAvocQShaCA6R8',
  authDomain: 'usaid-digileap.firebaseapp.com',
  projectId: 'usaid-digileap',
  storageBucket: 'usaid-digileap.appspot.com',
  messagingSenderId: '555527117880',
  appId: '1:555527117880:web:f790b9b63193700d93dacd',
  measurementId: 'G-VHX7WLNBJ7',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
logEvent(analytics, 'notification_received');
export const auth = getAuth(app);
export default app;
