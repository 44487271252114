import React from 'react';
import ReactDOM from 'react-dom';
import Container from './components/Container';
import firebase from 'firebase/compat/app';
import { UserAuthContextProvider } from './UserAuthContext';

import './assets/styles/index.scss';
import './assets/styles/typography.scss';

firebase.initializeApp({
  apiKey: 'AIzaSyCw9Y0-CrOd4fknLtbQqTAvocQShaCA6R8',
  authDomain: 'usaid-digileap.firebaseapp.com',
  projectId: 'usaid-digileap',
  storageBucket: 'usaid-digileap.appspot.com',
  messagingSenderId: '555527117880',
  appId: '1:555527117880:web:f790b9b63193700d93dacd',
  measurementId: 'G-VHX7WLNBJ7',
});

ReactDOM.render(
  <React.StrictMode>
    <UserAuthContextProvider>
      <Container />
    </UserAuthContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
