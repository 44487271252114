import { NavLink, useLocation } from 'react-router-dom';
import { useState } from 'react';
import Hamburger from '../Hamburger/Hamburger';
import './Nav.scss';

const links = [
  { name: 'Home', path: '/home' },
  { name: 'Digital Services', path: '/services' },
  { name: 'Partners', path: '/partners' },
  { name: 'Consultants', path: '/consultants' },
];

export default function Nav() {
  const [toggleHamburger, setToggleHamburger] = useState(false);

  const handleClick = () => setToggleHamburger(!toggleHamburger);

  const showNav = useLocation().pathname !== '/';

  return (
    <header className="header">
      <div className="container">
        <div className={showNav ? 'nav-wrapper' : 'nav-wrapper nav-wrapper--logo-only'}>
          {showNav && (
            <>
              <div
                data-testid="hamburger-btn-wrapper"
                onClick={handleClick}
                className="hamburger-wrapper"
              >
                <Hamburger isOpen={toggleHamburger} />
              </div>
              <nav>
                <ul className={toggleHamburger ? 'header__nav active' : 'header__nav'}>
                  {links.map((link, index) => (
                    <li key={index}>
                      <NavLink
                        className={({ isActive }) => (isActive ? 'selected-link' : 'none')}
                        to={link.path}
                        onClick={() => {
                          setToggleHamburger(false);
                        }}
                      >
                        {link.name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </nav>
            </>
          )}
        </div>
      </div>
    </header>
  );
}
