import { SelectOption } from '../components/Search/SearchFilter';

export const ThematicAreas: SelectOption[] = [
  { value: 'Thematic Areas_Artificial Intelligence', label: 'Artificial Intelligence' },
  { value: 'Thematic Areas_Ethics/Responsible Use', label: 'Ethics/Responsible Use' },
  { value: 'Thematic Areas_Digital Finance (FinTech) ', label: 'Digital Finance (FinTech)' },
  { value: 'Thematic Areas_Telecomm', label: 'Telecomm' },
  { value: 'Thematic Areas_5G', label: '5G' },
  { value: 'Thematic Areas_Gender Divide', label: 'Gender Divide' },
  { value: 'Thematic Areas_Internet of Things (IoT)', label: 'Internet of Things (IoT)' },
  {
    value: 'Thematic Areas_Geospatial Information Systems (GIS)',
    label: 'Geospatial Information Systems (GIS)',
  },
  { value: 'Thematic Areas_Digital Identity', label: 'Digital Identity' },
  { value: 'Thematic Areas_E-commerce/Digital Trade', label: 'E-commerce/Digital Trade' },
  { value: 'Thematic Areas_Social Media ', label: 'Social Media' },
  { value: 'Thematic Areas_Internet Governance ', label: 'Internet Governance' },
  { value: 'Thematic Areas_Data Ecosystems & Governance ', label: 'Data Ecosystems & Governance' },
  { value: 'Thematic Areas_Cybersecurity ', label: 'Cybersecurity' },
  { value: 'Thematic Areas_Human/User-Centered Design ', label: 'Human/User-Centered Design' },
  { value: 'Thematic Areas_Digital Government ', label: 'Digital Government' },
  { value: 'Thematic Areas_Last Mile Connectivity ', label: 'Last Mile Connectivity' },
  { value: 'Thematic Areas_Digital Skills/Literacy', label: 'Digital Skills/Literacy' },
];

export const TechnicalAreas: SelectOption[] = [
  { value: 'Technical Areas_Country Assessments', label: 'Country Assessments' },
  {
    value: 'Technical Areas_Convening, facilitation, and training',
    label: 'Convening, facilitation, and training',
  },
  {
    value: 'Technical Areas_Collaborative Learning and Adaptive Management',
    label: 'Collaborative Learning and Adaptive Management',
  },
  {
    value: 'Technical Areas_Partnership formation, facilitation, and management',
    label: 'Partnership formation, facilitation, and management',
  },
  { value: 'Technical Areas_Technical Assistance', label: 'Technical Assistance' },
  { value: 'Technical Areas_Communications', label: 'Communications' },
  {
    value: 'Technical Areas_Multimedia and graphic design',
    label: 'Multimedia and graphic design',
  },
  {
    value: 'Technical Areas_Training and workshop curriculum development',
    label: 'Training and workshop curriculum development',
  },
];

export const Sectors: SelectOption[] = [
  { value: 'Sector_Agriculture', label: 'Agriculture' },
  { value: 'Sector_Democracy & Governance', label: 'Democracy & Governance' },
  { value: 'Sector_Economic Development', label: 'Economic Development' },
  { value: 'Sector_Environment', label: 'Environment' },
  { value: 'Sector_Education', label: 'Education' },
  { value: 'Sector_Health', label: 'Health' },
  { value: 'Sector_Humanitarian Relief', label: 'Humanitarian Relief' },
  { value: 'Sector_Human Rights', label: 'Human Rights' },
  { value: 'Sector_Gender & Social Inclusion', label: 'Gender & Social Inclusion' },
  { value: 'Sector_Water, Sanitation & Hygiene', label: 'Water, Sanitation & Hygiene' },
];

export const Regions: SelectOption[] = [
  { value: 'Location_Africa', label: 'Africa' },
  { value: 'Location_Asia', label: 'Asia' },
  { value: 'Location_Europe & Eurasia', label: 'Europe & Eurasia' },
  { value: 'Location_Latin America & the Caribbean', label: 'Latin America & the Caribbean' },
  { value: 'Location_Middle East', label: 'Middle East' },
];

export const Countries: SelectOption[] = [
  { value: 'Africa_Angola', label: 'Angola' },
  { value: 'Africa_Benin', label: 'Benin' },
  { value: 'Africa_Botswana', label: 'Botswana' },
  { value: 'Africa_Burkina Faso', label: 'Burkina Faso' },
  { value: 'Africa_Cameroon', label: 'Cameroon' },
  { value: 'Africa_Central African Republic', label: 'Central African Republic' },
  { value: 'Africa_Chad', label: 'Chad' },
  { value: "Africa_Côte d'Ivoire", label: "Côte d'Ivoire" },
  { value: 'Africa_Democratic Republic of the Congo', label: 'Democratic Republic of the Congo' },
  { value: 'Africa_Djibouti', label: 'Djibouti' },
  { value: 'Africa_Eswatini', label: 'Eswatini' },
  { value: 'Africa_Ethiopia', label: 'Ethiopia' },
  { value: 'Africa_Ghana', label: 'Ghana' },
  { value: 'Africa_Guinea', label: 'Guinea' },
  { value: 'Africa_Kenya', label: 'Kenya' },
  { value: 'Africa_Lesotho', label: 'Lesotho' },
  { value: 'Africa_Liberia', label: 'Liberia' },
  { value: 'Africa_Madagascar', label: 'Madagascar' },
  { value: 'Africa_Malawi', label: 'Malawi' },
  { value: 'Africa_Mali', label: 'Mali' },
  { value: 'Africa_Mauritania', label: 'Mauritania' },
  { value: 'Africa_Mozambique', label: 'Mozambique' },
  { value: 'Africa_Namibia', label: 'Namibia' },
  { value: 'Africa_Niger', label: 'Niger' },
  { value: 'Africa_Nigeria', label: 'Nigeria' },
  { value: 'Africa_Republic of the Congo', label: 'Republic of the Congo' },
  { value: 'Africa_Rwanda', label: 'Rwanda' },
  { value: 'Africa_Senegal', label: 'Senegal' },
  { value: 'Africa_Sierra Leone', label: 'Sierra Leone' },
  { value: 'Africa_Somalia', label: 'Somalia' },
  { value: 'Africa_South Africa', label: 'South Africa' },
  { value: 'Africa_South Sudan', label: 'South Sudan' },
  { value: 'Africa_Sudan', label: 'Sudan' },
  { value: 'Africa_Tanzania', label: 'Tanzania' },
  { value: 'Africa_The Gambia', label: 'The Gambia' },
  { value: 'Africa_Uganda', label: 'Uganda' },
  { value: 'Africa_Zambia', label: 'Zambia' },
  { value: 'Africa_Zimbabwe', label: 'Zimbabwe' },
  { value: 'Asia_Afghanistan', label: 'Afghanistan' },
  { value: 'Asia_Bangladesh', label: 'Bangladesh' },
  { value: 'Asia_Burma', label: 'Burma' },
  { value: 'Asia_Cambodia', label: 'Cambodia' },
  { value: 'Asia_China', label: 'China' },
  { value: 'Asia_India', label: 'India' },
  { value: 'Asia_Indonesia', label: 'Indonesia' },
  { value: 'Asia_Kazakhstan', label: 'Kazakhstan' },
  { value: 'Asia_Kyrgyz Republic', label: 'Kyrgyz Republic' },
  { value: 'Asia_Laos', label: 'Laos' },
  { value: 'Asia_Maldives', label: 'Maldives' },
  { value: 'Asia_Mongolia', label: 'Mongolia' },
  { value: 'Asia_Nepal', label: 'Nepal' },
  { value: 'Asia_Pacific Islands', label: 'Pacific Islands' },
  { value: 'Asia_Pakistan', label: 'Pakistan' },
  { value: 'Asia_Philippines', label: 'Philippines' },
  { value: 'Asia_Sri Lanka', label: 'Sri Lanka' },
  { value: 'Asia_Tajikistan', label: 'Tajikistan' },
  { value: 'Asia_Thailand', label: 'Thailand' },
  { value: 'Asia_Timor-Leste', label: 'Timor-Leste' },
  { value: 'Asia_Turkmenistan', label: 'Turkmenistan' },
  { value: 'Asia_Uzbekistan', label: 'Uzbekistan' },
  { value: 'Asia_Vietnam', label: 'Vietnam' },
  { value: 'Europe/Eurasia_Albania', label: 'Albania' },
  { value: 'Europe/Eurasia_Armenia', label: 'Armenia' },
  { value: 'Europe/Eurasia_Azerbaijan', label: 'Azerbaijan' },
  { value: 'Europe/Eurasia_Belarus', label: 'Belarus' },
  { value: 'Europe/Eurasia_Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
  { value: 'Europe/Eurasia_Cyprus', label: 'Cyprus' },
  { value: 'Europe/Eurasia_Georgia', label: 'Georgia' },
  { value: 'Europe/Eurasia_Kosovo', label: 'Kosovo' },
  { value: 'Europe/Eurasia_Moldova', label: 'Moldova' },
  { value: 'Europe/Eurasia_Montenegro', label: 'Montenegro' },
  { value: 'Europe/Eurasia_North Macedonia', label: 'North Macedonia' },
  { value: 'Europe/Eurasia_Russia', label: 'Russia' },
  { value: 'Europe/Eurasia_Serbia', label: 'Serbia' },
  { value: 'Europe/Eurasia_Ukraine', label: 'Ukraine' },
  { value: 'Latin America & the Caribbean_Bolivia', label: 'Bolivia' },
  { value: 'Latin America & the Caribbean_Brazil', label: 'Brazil' },
  { value: 'Latin America & the Caribbean_Colombia', label: 'Colombia' },
  { value: 'Latin America & the Caribbean_Cuba', label: 'Cuba' },
  { value: 'Latin America & the Caribbean_Dominican Republic', label: 'Dominican Republic' },
  { value: 'Latin America & the Caribbean_Ecuador', label: 'Ecuador' },
  { value: 'Latin America & the Caribbean_El Salvador', label: 'El Salvador' },
  { value: 'Latin America & the Caribbean_Guatemala', label: 'Guatemala' },
  { value: 'Latin America & the Caribbean_Haiti', label: 'Haiti' },
  { value: 'Latin America & the Caribbean_Honduras', label: 'Honduras' },
  { value: 'Latin America & the Caribbean_Jamaica', label: 'Jamaica' },
  { value: 'Latin America & the Caribbean_Mexico', label: 'Mexico' },
  { value: 'Latin America & the Caribbean_Nicaragua', label: 'Nicaragua' },
  { value: 'Latin America & the Caribbean_Panama', label: 'Panama' },
  { value: 'Latin America & the Caribbean_Paraguay', label: 'Paraguay' },
  { value: 'Latin America & the Caribbean_Peru', label: 'Peru' },
  { value: 'Latin America & the Caribbean_Venezuela', label: 'Venezuela' },
  { value: 'Middle East_Egypt', label: 'Egypt' },
  { value: 'Middle East_Iraq', label: 'Iraq' },
  { value: 'Middle East_Jordan', label: 'Jordan' },
  { value: 'Middle East_Lebanon', label: 'Lebanon' },
  { value: 'Middle East_Libya', label: 'Libya' },
  { value: 'Middle East_Morocco', label: 'Morocco' },
  { value: 'Middle East_Syria', label: 'Syria' },
  { value: 'Middle East_Tunisia', label: 'Tunisia' },
  { value: 'Middle East_West Bank and Gaza', label: 'West Bank and Gaza' },
  { value: 'Middle East_Yemen', label: 'Yemen' },
];

export const USBased: SelectOption = { value: 'US-Based', label: 'US based' };
export const SmallBusiness: SelectOption = {
  value: 'Small or Disadvantaged Business',
  label: 'Small or Disadvantaged Business',
};
