import React, { useState, useEffect } from 'react';
import { Routes, Route, BrowserRouter, useNavigate } from 'react-router-dom';
import 'firebase/compat/auth';

// Components
import Nav from './Nav/Nav';
import Footer from './Footer/Footer';
import ScrollToTop from '../utils/scroll-to-top';

// Pages
import Home from '../pages/Home';
import Login from '../pages/Login';
import GlobalNetwork from '../pages/GlobalNetwork';
import Services from '../pages/Services';
import OurPeople from '../pages/OurPeople';
import { UserAuthContextProvider, useUserAuth } from '../UserAuthContext';

export default function Container() {
  localStorage.removeItem('searchIndex');
  const { user } = useUserAuth();

  return (
    <>
      <BrowserRouter>
        <UserAuthContextProvider>
          <ScrollToTop>
            <p className="disclaimer">
              <span className="semi-bold">Disclaimer:</span> This DigiLEAP Portal is a demonstration
              site created by Deloitte to illustrate a potential site for USAID’s Digital Strategy
              Implementation Program. The site content and layout can be modified in collaboration
              with ITR/T in compliance with USAID Brand guidelines.{' '}
            </p>
            <Nav />
            <main>
              <Routes>
                <Route exact path="/" element={<Login />} />
                <Route path="/home" element={user ? <Home /> : <Login />} />
                <Route path="/partners" element={user ? <GlobalNetwork /> : <Login />} />
                <Route path="/services" element={user ? <Services /> : <Login />} />
                <Route path="/consultants" element={user ? <OurPeople /> : <Login />} />
              </Routes>
            </main>
          </ScrollToTop>
        </UserAuthContextProvider>
      </BrowserRouter>
      <Footer />
    </>
  );
}
