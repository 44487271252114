import React from 'react';
import { ConsultantCardProps } from '../../components/ConsultantCard/ConsultantCard';

// Assets
const wayanVota = require('../images/wayan-vota.jpeg');
const stewartPG = require('../images/stewart-p.jpeg');
const jenniferDahnke = require('../images/jennifer-dahnke.jpeg');
const marciam = require('../images/marciac.jpeg');
const stevel = require('../images/stevelewarne.jpeg');
const jasonh = require('../images/jasonh.jpeg');
const jamies = require('../images/jamies.jpeg');
const tinam = require('../images/tinam.jpeg');
const tariq = require('../images/tariq.jpeg');
const hugo = require('../images/hugo.jpeg');
const mariad = require('../images/mariad.jpeg');
const javier = require('../images/javier.jpeg');
const samier = require('../images/samier.jpeg');
const jkwack = require('../images/jkwack.jpeg');
const elizv = require('../images/elizv.jpeg');
const johnp = require('../images/johnp.jpeg');
const briang = require('../images/briang.jpeg');
const chadc = require('../images/chadc.jpeg');
const mohm = require('../images/mohm.jpeg');
const pedro = require('../images/pedro.jpeg');

// Generic Placeholder Image
const constultantImage = require('../images/generic-grey.png');

const inCards: ConsultantCardProps[] = [
  {
    name: 'Elizabeth Villarroel',
    focus: ['Program Leadership', 'Cybersecurity'],
    locations: ['Afghanistan', 'Colombia', 'Moldova', 'Sudan', 'Ukraine'],
    yearsExperience: '19',
    bio: 'Chief of Party for USAID programs that are focused on technology solutions centered around health, economic development, and government reconstruction',
    linkedIn: 'https://www.linkedin.com/in/elizabeth-villarroel-4086ba3/',
    image: elizv,
  },
  {
    name: 'Wayan Vota',
    focus: ['Solution Design', 'Technology Implementation'],
    locations: ['Russia', 'China', 'Philippines'],
    yearsExperience: '18',
    bio: 'Nationwide technology program solutions design and implementation for development programs in health, education, agriculture, and economic development in 16 countries',
    linkedIn: 'https://www.linkedin.com/in/wayan/',
    image: wayanVota,
  },
  {
    name: 'Stewart Pierce-Gardner',
    focus: ['Project Management', 'Process Improvement'],
    locations: ['Jordan', 'Iraq', 'Yemen'],
    yearsExperience: '13',
    bio: 'Stewart conducts project management and process improvement for USAID programs using technology to improve private sector development, trade facilitation, and government decentralization.',
    linkedIn: 'https://www.linkedin.com/in/stewart-pierce-gardner-21493421/',
    image: stewartPG,
  },
  {
    name: 'Jennifer Dahnke',
    focus: ['Communications', 'Design Strategy', 'Knowledge Management', 'Organizational Learning'],
    locations: ['Uganda', 'Europe', 'Eurasia', 'Jamaica '],
    yearsExperience: '10+',
    bio: "Jennifer is experienced in communications and design strategy development and implementation, including 7 years at USAID leading the overall creative direction for the entire Agency's visual communication strategies and digital campaigns.",
    linkedIn: 'https://www.linkedin.com/in/jenniferdahnke/',
    image: jenniferDahnke,
  },
  {
    name: 'Jennifer Kwack',
    focus: ['Marketing', 'Graphic Design'],
    locations: ['USA'],
    yearsExperience: '8',
    bio: 'Jennifer focuses on marketing and communication strategy, human-centered design, digital campaigns, and vendor management for USAID and its 88 international offices.​',
    linkedIn: 'https://www.linkedin.com/in/jkwack/',
    image: jkwack,
  },
  {
    name: 'Marcia Matsubayashi',
    focus: ['Strategy and Technology'],
    locations: ['US', 'Canada', 'Japan', 'and several countries in Latin America'],
    yearsExperience: '35+',
    bio: 'Marcia leads the digital transformation practice. Marcia is an advisor to multiple technology, media and telecommunications companies and has international experience.',
    linkedIn: 'https://www.linkedin.com/in/marcia-ogawa-matsubayashi/',
    image: marciam,
  },
  {
    name: 'Steve Lewarne',
    focus: ['Economic Governance & Macro-Fiscal Policy', 'PFM', 'DRM and Trade Policy'],
    locations: [
      'Ukraine',
      'Jordan',
      'Honduras',
      'South Sudan',
      'Philippines',
      'Liberia',
      'Colombia',
      'Serbia',
      'Montenegro',
      'Afghanistan',
      'West Bank/Gaza',
    ],
    yearsExperience: '28+',
    bio: 'Steve is the lead Technical Advisor for the IDO Account and Lead Partner for USAID/Ukraine’s flagship Global Health. Steve has led multiple USAID projects as a leader.',
    linkedIn: 'https://www.linkedin.com/in/stephen-lewarne-a2a5a722/',
    image: stevel,
  },
  {
    name: 'Jason Hutchinson',
    focus: ['Digital & Emerging Technologies'],
    locations: ['Australia'],
    yearsExperience: '25+',
    bio: 'Jason is a founding member of Deloitte Digital. Jamie focuses on the public sector with clients including many of the Victorian Government departments and education sector with a primary interest in the use of digital to deliver service digitisation and improved customer engagement.',
    linkedIn: 'https://www.linkedin.com/in/jason-hutchison-5043533/',
    image: jasonh,
  },
  {
    name: 'Jamie Sawchuk',
    focus: ['Innovation & Public Sector'],
    locations: ['Canada'],
    yearsExperience: '25+',
    bio: "Jamie is the leader on Canada's Digital Techology Supercluster, leading Deloitte's Google practice in Western Canada. Jamie has experience in Digital Disruption, health inovation, technology strategy, and clean tech strategy.",
    linkedIn: 'https://www.linkedin.com/in/jamiesawchuk/',
    image: jamies,
  },
  {
    name: 'Tina Mendelson',
    focus: ['Strategy and Complex Transformations'],
    locations: [
      'Czech Republic',
      'Serbia',
      'Bulgaria',
      'Armenia',
      'Kazakhstan',
      'Kyrgyzstan',
      'Uzbekistan',
      'Tajikistan',
      'Turkmenistan',
      'MENA',
      'Georgia',
      'Nigeria',
      'Haiti',
      'Latin America',
      'Mozambique',
    ],
    yearsExperience: '20+',
    bio: 'Tina is experienced advising government agencies on strategy and complex transformations in 29 countries. Tine drives the Strategy practice’s focus to transform government agencies for the future, including by analytics and Artificial Intelligence use and behavioral change.',
    linkedIn: 'https://www.linkedin.com/in/tina-mendelson-66774a3/',
    image: tinam,
  },
  {
    name: 'Tariq Ajmal',
    focus: ['Cyber and Technology Risk'],
    locations: ['US', 'Canada', 'Middle East'],
    yearsExperience: '20+',
    bio: 'Tariq oversees regional practice addressing clients needs around strategic & reputation risk, technology & digital risk and cyber risks, process controls as well as helping organizations with regulatory compliance.',
    linkedIn: 'https://www.linkedin.com/in/tajmal/',
    image: tariq,
  },
  {
    name: 'Hugo Santos Pinto',
    focus: ['Telecoms Engineering and ICT', 'AI'],
    locations: ['Portugal', 'South Africa', 'Israel', 'South America', 'Europe'],
    yearsExperience: '20+',
    bio: 'Hugo is leading the partnership with TUPL, promoting Telecom Networks. Hugo works with engineering and operations automation using Artificial Intelligence.',
    linkedIn: 'https://www.linkedin.com/in/hugosantospinto/',
    image: hugo,
  },
  {
    name: 'Maria Demeke',
    focus: ['CMO', 'Human Capital and Strategy'],
    locations: ['Africa', 'Middle East', 'South America', 'Western Europe'],
    yearsExperience: '17+',
    bio: "Maria has led large, complex transformations for multi-lateral organizations, bilateral donor organizations and federal financial institutions. Maria's functional experience focuses on the learning and development, future of work, strategic planning, organizational design, program management and change management.",
    linkedIn: 'https://www.linkedin.com/in/maria-demeke-3143203/',
    image: mariad,
  },
  {
    name: 'Javier Huechao',
    focus: ['Strategy and Digital Cloud Platforms'],
    locations: ['Chile', 'Peru', 'Colombia', 'Bolivia', 'Argentina'],
    yearsExperience: '15+',
    bio: 'Javier is experienced in business and administration focused on Cloud platform solutions. Javier has implemented various platforms such as SAP and Salesforce.',
    linkedIn: 'https://www.linkedin.com/in/javier-huechao/',
    image: javier,
  },
  {
    name: 'Samier Abousaada',
    focus: ['Digital, Customer & Marketing'],
    locations: ['Europe'],
    yearsExperience: '15+',
    bio: 'Samier focuses on Public Sector and Financial Services. Samier has worked on a number of Digital and Agile delivery projects ranging from Digital Transformation, UX/Service Design, Target Operating Models, Contact Centre Transformation, IT Cost Reduction, Change Management, Channel Strategy and Customer Experience programmes.',
    linkedIn: 'https://www.linkedin.com/in/samierabousaada/',
    image: samier,
  },
  {
    name: 'John Piletich',
    focus: ['Open RAN Expert'],
    locations: ['Bosnia', 'Croatia', 'Serbia', 'Macedonia'],
    yearsExperience: '20+',
    bio: 'John is focused on helping clients redesign how cybersecurity and emerging technologies can better support their organizations, improve mission outcomes by adoption at scale, and build robust digital cultures. John has extensive experience in international development, working in developing countries, and project management, including taking complex tasks and breaking them down into manageable activities.',
    linkedIn: 'https://www.linkedin.com/in/john-piletich-0b0ab3105/',
    image: johnp,
  },
  {
    name: 'Brian Greenberg',
    focus: ['5G/Broadband Expert'],
    locations: ['Africa, Southeast Asia, Latin America'],
    yearsExperience: '20+',
    bio: "Brian's expertise includes business transformation, program management, strategic planning, capital planning, business process reengineering and design, acquisitions management, application requirements definition and design, connectivity strategy, and enterprise architecture. Brian currently serves as the GPS 5G/Edge Lead on the DoS Account.",
    linkedIn: 'https://www.linkedin.com/in/brian-greenberg-8992932/',
    image: briang,
  },
  {
    name: 'Chad Clay',
    focus: ['Deloitte Digital'],
    locations: ['United States'],
    yearsExperience: '15+',
    bio: 'Chad is an innovative digital leader who leads large-scale transformations of public and private sector organizations into holistic digital experiences. He brings technology, creativity, passion and innovation together to create and cultivate new and mature markets with tailored solutions that elevate the human experience.',
    linkedIn: 'https://www.linkedin.com/in/chadclay/',
    image: chadc,
  },
  {
    name: 'Mohamed Malouche',
    focus: ['Deloitte Tunisia Center for Digital Excellence'],
    locations: ['Tunisia'],
    yearsExperience: '25+',
    bio: 'Mohamed is in charge of public sector modernization working with international donors. He is responsible for all consulting activities in economic growth, local governance, energy, digital transformation, sustainable development and entrepreneurship projects, funded by international donors and international financial institutions.',
    linkedIn: 'https://www.linkedin.com/in/mohamed-malouche/',
    image: mohm,
  },
  {
    name: 'Pedro Sanguinho',
    focus: ['2G-5G, OpenRAN, IoT, SmartCities, Telecoms'],
    locations: ['Portugal', 'Japan', 'France', 'Australia'],
    yearsExperience: '20+',
    bio: 'Pedro is an experienced Telecom Engineer with a broad E2E knowledge of broadband networks with a solid background on Network Engineering and E2E Solutions. Pedro has leadership experience in multidisciplinary teams, different cultural environments and multi-technology projects from project delivery and execution, engineering management, business development and pipeline growth.',
    linkedIn: 'https://www.linkedin.com/in/pedro-sanguinho-75a55211/',
    image: pedro,
  },
];

export default inCards;
