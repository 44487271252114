import React from 'react';

// Components
import Banner from '../components/Banners/Banner';

// Interfaces
import ConsultantCard from '../components/ConsultantCard/ConsultantCard';
import inCards from '../assets/resources/internal-consultants';
import exCards from '../assets/resources/external-consultants';

// Assets
const bannerImage = require('../assets/images/pexels-thirdman-5256816.jpg');

const internalCards = inCards;
const externalCards = exCards;

export default function OurPeople() {
  return (
    <div className="p-realtive">
      <section className="section--dark-blue">
        <Banner
          title="Our Consultants"
          description="Similar to our global network, we have a network of consultants who specialize in various sectors, technical areas, thematic areas, and geographies. The following individuals are an example of potential partners that Deloitte can easily access. These individuals are not legally affiliated with Deloitte but have been identified based on their experience, capabilities, and expressed interest in working with us. Deloitte will conduct further due diligence prior to engaging the individuals.​"
          image={{ src: bannerImage, alt: 'Man pointing at colorful chart' }}
          type={'our-people'}
        />
      </section>
      <section className="section__standard">
        <div className="container">
          <div className="margin-top-60 margin-bottom-70">
            <p className="h5 margin-bottom-30">
              The following individuals are a sample of Deloitte employees and external specialists
              representing the range of geographic, thematic, and sectoral experience across our
              network.
            </p>
          </div>
          <section className="margin-top-40 margin-bottom-70">
            <h4>Deloitte Specialists</h4>
            <p>
              The following individuals are Deloitte employees who are members of the DigiLEAP
              leadership team or who may be engaged as advisors based on their geographic and
              technical experience. This is small sample of Deloitte’s global network of 360,000
              professionals.
            </p>
            <hr className="banner-alternate__hr" />
            <div className="consultant-card-container flex-row flex-gap-36-16">
              {internalCards.map((card, i) => (
                <ConsultantCard {...card} key={i} />
              ))}
            </div>
          </section>
          <section className="margin-top-60 padding-bottom-70">
            <h4>External Specialists</h4>
            <p>
              The individuals listed below have been identified as illustrative consultants for
              DigiLEAP within Deloitte’s network. These individuals are not legally affiliated with
              Deloitte but have been identified based on their experience, capabilities, and
              expressed interest in working with us. Deloitte will conduct further due diligence
              prior to engaging external specialists.
            </p>
            <hr className="banner-alternate__hr" />
            <div className="consultant-card-container flex-row flex-gap-36-16">
              {externalCards.map((card, i) => (
                <ConsultantCard {...card} key={i} />
              ))}
            </div>
          </section>
        </div>
      </section>
    </div>
  );
}
