import React from 'react';

import './BannerAlternate.scss';

interface BannerAltProps {
  title: string;
  mobileTitle?: string;
  image: { src: string; alt: string };
}

export default function BannerAlternate({
  title,
  image,
  mobileTitle,
  children,
}: React.PropsWithChildren<BannerAltProps>) {
  return (
    <div className="banner-alternate">
      <div className="banner-alternate__inner-container">
        <div className="d-mobile-only">
          <h2 className="h3">{mobileTitle ?? title}</h2>
          <hr className="banner-alternate__hr"></hr>
        </div>
        <div className="banner-alternate__mobile-bg"></div>
        <img src={image.src} className="banner-alternate__image" alt={image.alt} />
        <div>
          <h2 className="d-mobile-none">{title}</h2>
          <hr className="banner-alternate__hr d-mobile-none"></hr>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
}
