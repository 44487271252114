import React from 'react';
import { Link } from 'react-router-dom';
import './LinkCard.scss';

export interface LinkCardProps {
  title: string;
  description: string;
  icon: any;
  link: {
    title: string;
    href: string;
  };
}

export default function LinkCard({ title, description, link, icon }: LinkCardProps) {
  return (
    <div className="linkcard__container">
      <div className="linkcard__text-container">
        <img className="linkcard__icon" src={icon} alt="" />
        <h3 className="linkcard__title">{title}</h3>
        <p className="linkcard__description">{description}</p>
      </div>
      <Link to={link.href} className="linkcard__link">
        {link.title}
      </Link>
    </div>
  );
}
