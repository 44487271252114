import React from 'react';

import './ConsultantCard.scss';
import ExternalLink from '../ExternalLink/ExternalLink';

// assets
const linkedInLogo = require('../../assets/images/icons/linkedIn-logo.png');

export interface ConsultantCardProps {
  name: string;
  focus: string[];
  locations: string[];
  yearsExperience: string;
  bio: string;
  linkedIn: string;
  image: any;
}

export default function ConsultantCard({
  name,
  focus,
  locations,
  yearsExperience,
  bio,
  linkedIn,
  image,
}: ConsultantCardProps) {
  return (
    <div className="constultant-card">
      <div className="constultant-card__inner">
        <div className="constultant-card__heading">
          <img
            className="constultant-card__profile-picture"
            src={image}
            alt={`Profile picture for ${name}`}
          />
          <div>
            <h5>{name}</h5>
            <p className="bold line-height-short margin-top-0 ">{focus.join(', ')}</p>
            <p className="italic line-height-short">{locations.join(', ')}</p>
            <p className="semi-bold line-height-short">
              {yearsExperience} {yearsExperience !== '1' ? 'years' : 'year'} of experience
            </p>
          </div>
        </div>
        <div className="constultant-card__body">
          <p>{bio}</p>
        </div>
        <div className="constultant-card__footer">
          <img src={linkedInLogo} alt="logo for LinkedIn" />
          <ExternalLink href={linkedIn}>View on LinkedIn</ExternalLink>
        </div>
      </div>
    </div>
  );
}
