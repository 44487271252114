import React from 'react';

// COMPONENTS
import RoundedCard, { RoundedCardProps } from '../components/Cards/RoundedCard';
import BannerAlternate from '../components/Banners/BannerAlternate';
import SearchContainer from '../components/Search/SearchContainer';
import Banner from '../components/Banners/Banner';

// LOGO ASSETS
const partnerBannerImage = require('../assets/images/partners_banner.png');
const partnerAltBanner = require('../assets/images/partners-alt-hero.png');
const deloitteLogo = require('../assets/images/deloitte.png');
const googleLogo = require('../assets/images/google.png');
const mit = require('../assets/images/mitdlab.jpeg');
const qed = require('../assets/images/q2.png');
const techChange = require('../assets/images/techchange.jpeg');
const ideo = require('../assets/images/ideo-logo-vector.png');
const makingCents = require('../assets/images/making-cents.png');

const partnerData: RoundedCardProps[] = [
  {
    alt: 'Deloitte logo',
    image: deloitteLogo,
    primary: true,
    tag: 'Innovation Leader',
    description:
      'With more than 30+ years experience as a USAID implementing partner, Deloitte enables and accelerates digital for development with governments and the private sector, strengthening digital workforces, organizations and ecosystems, globally.',
    link: {
      title: 'Visit website',
      href: 'https://www2.deloitte.com/us/en.html',
    },
  },
  {
    alt: 'Google logo',
    image: googleLogo,
    primary: true,
    tag: 'Technology Leader',
    description:
      'Google has a global footprint and  works with the tech industry as a strategy & implementation partner for scalable impact',
    link: {
      title: 'Visit website',
      href: 'https://about.google/',
    },
  },
  {
    alt: 'MIT D-Lab logo',
    image: mit,
    primary: true,
    tag: 'Research Leader',
    description:
      'MIT D-Lab’s work in participatory design and inclusive innovation teaches leaders and learners how to discern when (or if) to use a participatory design process to solve a challenge. Through participatory design, people can learn how to connect more meaningfully with people of diverse perspectives, and to make decisions with them collectively and quickly. This can lead to more desirable and sustainable solutions.',
    link: {
      title: 'Visit website',
      href: 'https://d-lab.mit.edu/',
    },
  },
  {
    alt: 'Q2 Impact logo',
    image: qed,
    primary: false,
    tag: 'CLA Specialist',
    description:
      'Q2 Impact, formerly The QED Group, LLC (QED) collects, analyzes, visualizes, and uses data in more effective ways by leveraging deep expertise in monitoring, evaluation, global health, and learning. QED uses a collaborative, integrated approach to deliver cross-sectoral solutions that leverage and build local capacity for truly sustainable progress that maximizes results through the use of cutting-edge technologies and innovation, organizational development, and evidence-based decision-making.',
    link: {
      title: 'Visit website',
      href: 'https://www.q2impact.com/',
    },
  },
  {
    alt: 'TechChange logo',
    image: techChange,
    primary: false,
    tag: 'Communications Specialist',
    description:
      'TechChange builds powerful online courses for synchronous, blended, or self-directed learning. Trusted by over 250 of the leading organizations in social impact, philanthropy, global health, and international development, TechChange provides a lightweight, accessible platform with a focus on community and peer-to-peer interaction is critical to engaging global audiences, especially in the Global South.',
    link: {
      title: 'Visit website',
      href: 'https://www.techchange.org/',
    },
  },
  {
    alt: 'IDEO logo',
    image: ideo,
    primary: false,
    tag: 'Human-Centered Design Specialist',
    description:
      'IDEO creates human-centered products, services, spaces, and organizations that empower communities, cities, and even countries. IDEO connects emerging technology to everyday needs and aspirations in fields such as biodesign, life science, health, and data.',
    link: {
      title: 'Visit website',
      href: 'https://cantwait.ideo.com/',
    },
  },
  {
    alt: 'MakingCents logo',
    image: makingCents,
    primary: false,
    tag: 'Inclusive Development Specialist',
    description:
      'Founded on the principles of social entrepreneurship, Making Cents International uses evidence-based, market-driven approaches to support those most in need: Young leaders advocating for change, smallholder farmers feeding our planet, women entrepreneurs launching and growing businesses, families displaced by conflict and disasters struggling to rebuild their lives. In doing this important work, Making Cents International partners with local organizations to create inclusive and supportive social and economic ecosystems where all individuals and groups can thrive and live fulfilling lives.',
    link: {
      title: 'Visit website',
      href: 'https://makingcents.com/',
    },
  },
];

export default function GlobalNetwork() {
  return (
    <>
      <section className="section--dark-blue">
        <Banner
          title="Our Partners"
          image={{ src: partnerBannerImage, alt: 'globe' }}
          type="partners"
          description={
            'We will provide specialized technical assistance to USAID Missions and Washington OUs with industry leaders and disruptors assembled from across the globe. Our team includes a diverse network of technology firms, academic institutions, and development organizations with expertise and experience in areas such as CLA, human-centered design, communications, inclusion, and more.'
          }
        />
      </section>
      <section className="section__standard padding-top-70 padding-bottom-70 ">
        <div className="container container__standard">
          <div className="flex-row flex-col-gap-24 margin-bottom-30">
            {partnerData.map(
              (card, index) => card.primary && <RoundedCard key={index} {...card} />
            )}
          </div>
          <div className="flex-row margin-bottom-30 flex-col-gap-24">
            {partnerData.map(
              (card, index) => !card.primary && <RoundedCard key={index} {...card} />
            )}
          </div>
        </div>
      </section>
      <section className="partners-page__banner-section">
        <BannerAlternate
          title="Explore our Growing Global Network"
          mobileTitle="Our network goes further"
          image={{
            src: partnerAltBanner,
            alt: 'Woman smiling and shaking hand of someone out of frame',
          }}
        >
          <p>
            We also have Deloitte member firms around the world who are full local entities by USAID
            definition and uphold Deloitte USA&apos;s high-performance expectations.
          </p>
          <br></br>
          <p>
            In addition, we have a network of specialized independent firms that operate across
            geographies, sectors, and technical areas. Many of these firms have prior USAID
            experience and are small and disadvantaged, providing them unique skill sets and
            perspectives to assist in the effort to bridge the digital divide, promote equitable
            growth, and equip people with the ability to safely navigate a new digital environment.
          </p>
          <br></br>
          <p>
            The following entities are an example of potential partners that Deloitte can easily
            access. These entities are not legally affiliated with Deloitte but have been identified
            based on their experience, capabilities, and expressed interest in working with us.
            Deloitte will conduct further due diligence prior to engaging the entity as a grantee or
            subcontractor.
          </p>
        </BannerAlternate>
      </section>
      <section className="section__standard">
        <div className="container container__standard">
          <h2 className="padding-bottom-20 padding-top-30">Initial Organizations</h2>
          <p className="bold padding-top-20 margin-bottom-40 width-60p">
            Organizations listed within our global network have been identified as potential
            partners for the USAID DSIPA pursuit but have not necessarily completed our entire
            risk-process and clearance at this time.
          </p>
          <SearchContainer />
        </div>
      </section>
    </>
  );
}
