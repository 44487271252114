import React, { useState } from 'react';
import { SearchResult } from '../../search/search';
import {
  Countries,
  Regions,
  Sectors,
  SmallBusiness,
  TechnicalAreas,
  ThematicAreas,
  USBased,
} from '../../utils/filter-lists';
import { SelectOption } from './SearchFilter';
import './SearchResultCard.scss';

interface SearchResultCardProps {
  result: SearchResult;
  filterCb: (option: SelectOption[]) => void;
}

function generateTagList(filterList: SelectOption[], result: SearchResult): SelectOption[] {
  return filterList.filter((item) => {
    const value = item.value;
    return result[value] && result[value] === 'TRUE';
  });
}

function determineLabel(singular: string, plural: string, numberOfTags: number): string {
  return `${numberOfTags} ${numberOfTags === 1 ? singular : plural} `;
}

function showHideButtons(
  state: boolean,
  stateSetter: (value: React.SetStateAction<boolean>) => void
) {
  return !state ? (
    <button
      onClick={() => stateSetter(true)}
      className="button-link"
      aria-label="Show additional filters"
    >
      &nbsp;(show)
    </button>
  ) : (
    <button
      onClick={() => stateSetter(false)}
      className="button-link"
      aria-label="Hide additional filters"
    >
      &nbsp;hide
    </button>
  );
}

export default function SearchResultCard({ result, filterCb }: SearchResultCardProps) {
  const [thematicAreasExpanded, setThematicAreasExpanded] = useState(false);
  const [technicalAreasExpanded, setTechnicalAreasExpanded] = useState(false);
  const [sectorsExpanded, setSectorsExpanded] = useState(false);
  const [countriesExpanded, setCountriesExpanded] = useState(false);
  const [regionsExpanded, setRegionsExpanded] = useState(false);

  const thematicAreasTags = generateTagList(ThematicAreas, result);
  const technicalAreasTags = generateTagList(TechnicalAreas, result);
  const sectorsTags = generateTagList(Sectors, result);
  const regionsTags = generateTagList(Regions, result);
  const countriesTags = generateTagList(Countries, result);
  const isUSBased = result[USBased.value] && result[USBased.value] === 'TRUE';
  const isSmallBusiness = result[SmallBusiness.value] && result[SmallBusiness.value] === 'TRUE';

  const handleClick = (option: SelectOption) => {
    filterCb([option]);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>, option: SelectOption) => {
    if (e.key === 'Enter') {
      handleClick(option);
    }
  };

  return (
    <div className="search-result__container">
      <h4 className="search-result__title">{result['Organization Name']}</h4>
      <p className="search-result__description">{result.Description}</p>

      <div className="search-result__tags-container margin-bottom-12">
        {isUSBased && (
          <div
            role="button"
            tabIndex={0}
            className="search-filter-tag"
            onClick={() => handleClick(USBased)}
            onKeyDown={(e) => handleKeyPress(e, USBased)}
          >
            {USBased.label}
          </div>
        )}
        {isSmallBusiness && (
          <div
            role="button"
            tabIndex={0}
            className="search-filter-tag"
            onClick={() => handleClick(SmallBusiness)}
            onKeyDown={(e) => handleKeyPress(e, SmallBusiness)}
          >
            {SmallBusiness.label}
          </div>
        )}
      </div>

      <div className="search-result__tags-container">
        {regionsTags.length > 0 && (
          <div className="search-filter-tag search-filter-tag--region">
            {determineLabel('Region', 'Regions', regionsTags.length)}
            {showHideButtons(regionsExpanded, setRegionsExpanded)}
          </div>
        )}

        {countriesTags.length > 0 && (
          <div className="search-filter-tag search-filter-tag--country">
            {determineLabel('Country', 'Countries', countriesTags.length)}
            {showHideButtons(countriesExpanded, setCountriesExpanded)}
          </div>
        )}

        {sectorsTags.length > 0 && (
          <div className="search-filter-tag search-filter-tag--sector">
            {determineLabel('Sector', 'Sectors', sectorsTags.length)}
            {showHideButtons(sectorsExpanded, setSectorsExpanded)}
          </div>
        )}

        {thematicAreasTags.length > 0 && (
          <div className="search-filter-tag search-filter-tag--thematic">
            {determineLabel('Thematic area', 'Thematic areas', thematicAreasTags.length)}
            {showHideButtons(thematicAreasExpanded, setThematicAreasExpanded)}
          </div>
        )}

        {technicalAreasTags.length > 0 && (
          <div className="search-filter-tag search-filter-tag--technical">
            {determineLabel('Technical area', 'Technical areas', technicalAreasTags.length)}
            {showHideButtons(technicalAreasExpanded, setTechnicalAreasExpanded)}
          </div>
        )}

        {/* Multi Tags */}
        {regionsExpanded && (
          <div className="search-result__tags-container">
            {regionsTags.map((tag, i) => (
              <div
                role="button"
                tabIndex={0}
                onClick={() => handleClick(tag)}
                onKeyDown={(e) => handleKeyPress(e, tag)}
                key={i}
                className="search-filter-tag search-filter-tag--region"
              >
                Region: {tag.label}
              </div>
            ))}
          </div>
        )}

        {countriesExpanded && (
          <div className="search-result__tags-container">
            {countriesTags.map((tag, i) => (
              <div
                role="button"
                tabIndex={0}
                onClick={() => handleClick(tag)}
                onKeyDown={(e) => handleKeyPress(e, tag)}
                key={i}
                className="search-filter-tag search-filter-tag--country"
              >
                {tag.label}
              </div>
            ))}
          </div>
        )}

        {sectorsExpanded && (
          <div className="search-result__tags-container">
            {sectorsTags.map((tag, i) => (
              <div
                role="button"
                tabIndex={0}
                onClick={() => handleClick(tag)}
                onKeyDown={(e) => handleKeyPress(e, tag)}
                key={i}
                className="search-filter-tag search-filter-tag--sector"
              >
                {tag.label}
              </div>
            ))}
          </div>
        )}

        <div className="search-result__tags-container">
          {thematicAreasExpanded &&
            thematicAreasTags.map((tag, i) => (
              <div
                role="button"
                tabIndex={0}
                onClick={() => handleClick(tag)}
                onKeyDown={(e) => handleKeyPress(e, tag)}
                key={i}
                className="search-filter-tag search-filter-tag--thematic"
              >
                {tag.label}
              </div>
            ))}
        </div>

        {technicalAreasExpanded && (
          <div className="search-result__tags-container">
            {technicalAreasExpanded &&
              technicalAreasTags.map((tag, i) => (
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => handleClick(tag)}
                  onKeyDown={(e) => handleKeyPress(e, SmallBusiness)}
                  key={i}
                  className="search-filter-tag search-filter-tag--technical"
                >
                  {tag.label}
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}
